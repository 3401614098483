import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Form, InputGroup } from "react-bootstrap";

import { ConfigContext } from "../context";
import { getPlatformsData } from "../actions/platformsActions";
import { getInstallsData, getInstallsFiltersData } from "../actions/installsActions";
import CustomDatepicker from "../components/UI/CustomDatepicker";
import CustomDropdown from "../components/UI/CustomDropdown";
import Loader from "../components/UI/Loader";
import Filters from "../components/Filters";
import AppliedFilters from "../components/AppliedFilters";
import CustomPagination from "../components/CustomPagination";
import searchIcon from "../assets/images/search.svg";
import userAvatar from "../assets/images/user.svg";

const newUTCDate = () => {
	const date = new Date();
	return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
		date.getUTCDate(), date.getUTCHours(),
		date.getUTCMinutes(), date.getUTCSeconds());
}
const initialDateFilter = () => {
	let start = new Date(newUTCDate());
	start.setDate(start.getDate() - 30);
	let end = new Date();
	return { [`created_at[]`]: [Math.floor(start.getTime() / 1000), Math.floor(end.getTime() / 1000)] };
}

export default function Installs() {

	const { appConfig } = useContext(ConfigContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const platformsStore = useSelector(state => state.platforms);
	const filtersStore = useSelector(state => state.installsFilters);
	const installsStore = useSelector(state => state.installs);
	const savedFilters = JSON.parse(localStorage.getItem("installsAppliedFilters")) || initialDateFilter();
	const [tableLoading, setTableLoading] = useState(true);
	const [columnsToHide, setColumnsToHide] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState(savedFilters);
	const _user_token = localStorage.getItem("userToken");
	const [initialized, setInitialized] = useState(false);
	const [selectedApp, setSelectedApp] = useState(null);

	const updateInstallsList = (params = {}) => {
		dispatch(getInstallsData(`/api/v1/frontend/installs/list`, { _user_token, application_id: userInfoStore.data.selected.application, ...params }));
	}

	const handleColumns = (colName, hide) => {
		if (hide) setColumnsToHide(columnsToHide.filter(x => x !== colName));
		else setColumnsToHide([...columnsToHide, colName]);
	}

	const handleAppliedFilters = (type, key, value) => {
		if (type === "list" || type === "assoc list") {

			if (key in appliedFilters) {
				if (appliedFilters[key].includes(value)) {
					if (appliedFilters[key].length > 1) {
						setAppliedFilters({ ...appliedFilters, [key]: appliedFilters[key].filter(x => x !== value) });
					} else {
						setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
					}
				} else {
					setAppliedFilters({ ...appliedFilters, [key]: [...appliedFilters[key], value] });
				}
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: [value] });
			}

		} else if (type === "boolean" || type === "num_range") {

			if (!value) {
				setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: value });
			}

		}
	}

	const handleRemoveFilter = (filterName) => {
		setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([key, _]) => key !== filterName)));
	}

	const handleFilterDate = (date) => {
		setAppliedFilters({ ...appliedFilters, ...date });
	}

	const handleSearch = (e) => {
		if (e.target.value.length > 5) {
			setAppliedFilters({ ...appliedFilters, customer_search: e.target.value });
			updateInstallsList({ ...appliedFilters, customer_search: e.target.value }, "handleSearch");
		} else if (e.target.value.length === 0) {
			setAppliedFilters({ ...appliedFilters, customer_search: "" });
			updateInstallsList({ ...appliedFilters, customer_search: "" }, "handleSearch");
		}
	}

	const handlePage = (num) => {
		setAppliedFilters({ ...appliedFilters, page: num });
	}

	const handleGoToCustomerCard = (id) => {
		window.open(`/customers/${id}`, "_blank");
	}

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		}).format(timestamp * 1000);
	}

	const filtersToURLparams = (filters) => {
		let URLparams = "";
		for (let i = 0, keys = Object.keys(filters); i < keys.length; ++i) {
			if (Array.isArray(filters[keys[i]]))
				for (const arg of filters[keys[i]]) URLparams += `&${keys[i]}=${arg}`;
			else URLparams += `&${keys[i]}=${filters[keys[i]]}`;
		}
		return URLparams;
	}

	useEffect(() => {
		if (!platformsStore.loaded)
			dispatch(getPlatformsData(`/api/v1/frontend/platforms/list`));
		if (!filtersStore.loaded)
			dispatch(getInstallsFiltersData(`/api/v1/frontend/installs/filters`, { _user_token, application_id: userInfoStore.data.selected.application }));
	}, [])

	useEffect(() => {
		if (installsStore.loaded && platformsStore.loaded) setTableLoading(false);
		else setTableLoading(true);
	}, [installsStore.loaded, platformsStore.loaded])

	useEffect(() => {
		const local = localStorage.getItem("installsAppliedFilters");
		const applied = JSON.stringify((appliedFilters));

		if (!initialized || local !== applied || selectedApp !== userInfoStore.data.selected.application) {
			setInitialized(true);
			setSelectedApp(userInfoStore.data.selected.application);
			if (Object.keys(appliedFilters).length > 0 && appliedFilters[`created_at[]`][0] && appliedFilters[`created_at[]`][1]) {
				updateInstallsList(appliedFilters);
				localStorage.setItem("installsAppliedFilters", applied);
			}
		}
	}, [userInfoStore.data.selected.application, appliedFilters])

	return (
		<Container className="px-2 px-lg-5">

			{appliedFilters.test &&
				<Row className="bg-primary m-0">
					<Col className="d-flex justify-content-center py-2 px-0">
						<p className="text-white m-0">
							{appConfig.Installs.wording.text_1}
						</p>
					</Col>
				</Row>
			}

			<Row className="d-flex align-items-center justify-content-between pt-4 m-0">
				<Col xs={6} lg="auto" className="px-0 ps-0 pe-lg-3">
					<Row className="m-0 d-flex align-items-baseline">
						<Col xs={12} lg="auto" className="px-0">
							<h2 className="SFProDisplay-Bold m-0">
								{appConfig.Installs.wording.text_2}
							</h2>
						</Col>
						<Col xs={12} lg="auto" className="px-0 px-lg-3">
							<p className="m-0">
								{appConfig.Installs.wording.text_3} {installsStore.data ? installsStore.data.total_items : 0} {appConfig.Installs.wording.text_4}
							</p>
						</Col>
					</Row>
				</Col>
				<Col xs={6} lg="auto" className="px-0 ps-0 pe-lg-3">

				</Col>
			</Row>

			{filtersStore.loaded &&
				<Row className="d-flex justify-content-between align-items-center pt-2 m-0">
					<Col xs={12} lg="auto" className="px-0">
						<Filters
							availableFilters={filtersStore.data}
							appliedFilters={appliedFilters}
							handleAppliedFilters={handleAppliedFilters}
							applyFilters={() => updateInstallsList(appliedFilters)}
						/>
					</Col>

					<Col xs={12} lg="auto" className="pt-3 pt-lg-0 px-0">
						<Row className="d-flex align-items-center m-0">

							<Col xs={12} lg="auto" className=" px-0 px-lg-2">
								<InputGroup className="border border-2 border-secondary rounded-5">
									<InputGroup.Text className="border-0">
										<img src={searchIcon} className="img-fluid" alt="search" />
									</InputGroup.Text>
									<Form.Control
										defaultValue={appliedFilters.customer_search}
										type="text"
										placeholder={appConfig.Installs.wording.text_6}
										className="border-0 ps-0 pe-5"
										onChange={handleSearch}
									/>
								</InputGroup>
							</Col>

							<Col xs={12} lg="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
								<CustomDatepicker
									dateFilterKey="created_at[]"
									appliedDate={appliedFilters[`created_at[]`]}
									changeDate={handleFilterDate}
									dateKey="installs"
									endCorrection={1}
									showToday
								/>
							</Col>

							<Col xs={6} lg="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
								<a
									href={`${window.location.origin}/api/v1/frontend/installs/export?${filtersToURLparams({ _user_token, application_id: userInfoStore.data.selected.application, ...appliedFilters })}`}
									className="btn btn-secondary rounded-5"
									download="export_installs.csv"
								>
									{appConfig.Installs.wording.text_7}
								</a>
							</Col>

							<Col xs={6} lg="auto" className="text-end pt-3 pt-lg-0 px-0 ps-lg-2 pe-lg-0">
								<CustomDropdown buttonText="Pick columns">
									<div className="white-space-nowrap py-2 px-3">
										{installsStore.loaded && Object.keys(installsStore.data.columns).map(col => (
											<div className="py-1" key={col}>
												<Form.Label className="d-flex" htmlFor={col}>
													<Form.Check
														id={col}
														defaultChecked
														onChange={(e) => handleColumns(col, e.target.checked)}
													/>
													<span className="ps-2">
														{installsStore.data.columns[col]}
													</span>
												</Form.Label>
											</div>
										))}
									</div>
								</CustomDropdown>
							</Col>

						</Row>
					</Col>
				</Row>
			}

			{filtersStore.loaded &&
				< Row className="pt-2 m-0">
					<Col className="px-0">
						<AppliedFilters
							appliedFilters={appliedFilters}
							exceptFilters={["created_at[]", "customer_search", "page", "test"]}
							availableFilters={filtersStore.data}
							handleRemoveFilter={handleRemoveFilter}
						/>
					</Col>
				</Row>
			}

			{tableLoading ? <Loader /> :
				<Container fluid className="px-0">
					<Row className="pt-4 m-0">
						<Col className="px-0">
							<Table responsive>

								<thead>
									<tr>
										{Object.keys(installsStore.data.columns).map((col, i) => !columnsToHide.includes(col) && (
											<th key={i} className={`${col === "ltv" ? "text-end " : ""} opacity-50 fw-normal border-0 custom-cell`}>
												{installsStore.data.columns[col]}
											</th>
										))}
									</tr>
								</thead>

								<tbody className="border-top-0">
									{installsStore.data.records.length > 0
										?
										installsStore.data.records.map((record, i) => (
											<tr key={i}>
												{Object.keys(installsStore.data.columns).map((col, index) => {
													if (!columnsToHide.includes(col)) {
														if (col === "customer_ids") return (
															<td key={index} className="custom-cell">
																{record[col] !== null && record[col].length > 0 && record[col].map(id => {
																	return (
																		<div className="cursor-pointer d-flex align-items-center m-0" onClick={() => handleGoToCustomerCard(id)} key={id}>
																			<img src={userAvatar} height="11px" alt="avatar" />
																			<span className="ps-1">
																				{id}
																			</span>
																		</div>
																	);
																})}
															</td>
														);
														else if (col === "platform") return (
															<td key={index} className="custom-cell">
																{record[col] && platformsStore.data.find(x => x.id === record[col]).name}
															</td>
														);
														else if (col === "created_at" || col === "updated_at") return (
															<td key={index} className="custom-cell">
																{timestampToDate(record[col])}
															</td>
														);
														else return (
															<td key={index} className={`${col === "ltv" ? "text-end " : ""} custom-cell`}>
																{record[col] !== null && `${record[col]}`}
															</td>
														);
													}
													else return null;
												})}
											</tr>
										))
										:
										<tr>
											<td className="text-center custom-cell border-none opacity-50 pt-5" colSpan={Object.keys(installsStore.data.columns).length}>
												{appConfig.Installs.wording.text_8}
											</td>
										</tr>
									}
								</tbody>

							</Table>
						</Col>
					</Row>

					<Row className="m-0">
						<Col className="px-0">
							<CustomPagination
								totalPages={installsStore.data.total_pages}
								currentPage={installsStore.data.current_page}
								handlePage={handlePage}
							/>
						</Col>
					</Row>
				</Container>
			}

		</Container >
	);
}