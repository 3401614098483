import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

import { ConfigContext } from "../context";
import { getUsersData, getRolesData } from "../actions/teamMembersActions";
import CustomModal from "../components/CustomModal";
import Loader from "../components/UI/Loader";
import TeamMembersList from "../components/TeamMembersList";
import TeamMembersEdit from "../components/TeamMemberEdit";
import TeamMembersDelete from "../components/TeamMemberDelete";
import Notification from "../components/UI/Notification";

export default function TeamMembers() {

	const history = useHistory();
	const { appConfig } = useContext(ConfigContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const rolesStore = useSelector(state => state.roles);
	const usersStore = useSelector(state => state.users);
	const [applicationsList, setApplicationsList] = useState(userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).applications);
	const [tableLoading, setTableLoading] = useState(true);
	const [modalContent, setModalContent] = useState("");
	const [userData, setUserData] = useState({});
	const [appsIds, setAppsIds] = useState([]);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const _user_token = localStorage.getItem("userToken");
	const [showModal, setShowModal] = useState(false);

	const handleModal = () => {
		setShowModal(!showModal);
	}

	const handleModalContent = (type, userData) => {
		setModalContent(type);
		if (type === "create")
			setUserData({
				name: "",
				email: "",
				password: "",
				password_confirmation: "",
				role: 2,
				application_ids: [],
			});
		else setUserData(userData);
		handleModal();
	}

	const handleUserData = (data) => {
		setUserData(data);
	}

	const handleSubmit = () => {
		if ("user_id" in userData) {
			submitUserData("edit");
		} else {
			submitUserData("create");
		}
	}

	const submitUserData = async (action) => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/users/${action}`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							organization_id: userInfoStore.data.selected.organization,
							...userData
						})
					})
			).json();
			if (response._code === 200) {
				handleModal();
				handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
				dispatch(getUsersData(`/api/v1/frontend/users/list`, { _user_token, organization_id: userInfoStore.data.selected.organization }));
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleDelete = async (id) => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/users/delete`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							organization_id: userInfoStore.data.selected.organization,
							user_id: id
						})
					})
			).json();
			if (response._code === 200) {
				if (id === userInfoStore.data.list.id) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}
				handleModal();
				handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
				dispatch(getUsersData(`/api/v1/frontend/users/list`, { _user_token, organization_id: userInfoStore.data.selected.organization }));
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	useEffect(() => {
		if (!usersStore.loaded)
			dispatch(getUsersData(`/api/v1/frontend/users/list`, { _user_token, organization_id: userInfoStore.data.selected.organization }));
		if (!rolesStore.loaded)
			dispatch(getRolesData(`/api/v1/frontend/roles/list`));
		if (userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).role !== 1)
			history.push("/");
	}, [])

	useEffect(() => {
		if (usersStore.error || rolesStore.error) {
			const error = usersStore.error || rolesStore.error;
			handleNotification({ show: true, title: "Error", text: error, time: "Now" });
		}
	}, [usersStore.error, rolesStore.error])

	useEffect(() => {
		if (usersStore.loaded && rolesStore.loaded) setTableLoading(false);
	}, [usersStore.loaded, rolesStore.loaded])

	useEffect(() => {
		setApplicationsList(userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).applications);
		let apps = [];
		for (let i = 0; i < applicationsList.length; ++i) {
			apps.push(applicationsList[i].id);
		}
		setAppsIds(apps);
	}, [userInfoStore.data.selected])

	return (
		<Container className="pt-4 pb-5 px-2 px-lg-5">

			<Notification notification={notification} handleNotification={handleNotification} />

			<Row className="d-flex align-items-center m-0">
				<Col className="px-0">
					<h2 className="SFProDisplay-Bold m-0">
						{appConfig.TeamMembers.wording.text_1}
					</h2>
				</Col>
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={() => handleModalContent("create")}>
						{appConfig.TeamMembers.wording.text_2}
					</Button>
				</Col>
			</Row>

			{tableLoading ? <Loader /> :
				<TeamMembersList
					userId={userInfoStore.data.list.id}
					userRole={userInfoStore.data.list.organizations.find(x => x.id === userInfoStore.data.selected.organization).role}
					usersList={usersStore.data.users}
					rolesList={rolesStore.data}
					applicationsList={applicationsList}
					handleModalContent={handleModalContent}
					config={appConfig.TeamMembers.teamMembersList}
				/>
			}

			<CustomModal
				handleModal={handleModal}
				show={showModal}
				closeButton={false}
				fullscreen={false}
			>
				{modalContent === "delete"
					?
					<TeamMembersDelete
						id={userData.user_id}
						name={userData.name}
						onCancel={handleModal}
						onSubmit={handleDelete}
						config={appConfig.TeamMembers.teamMembersDelete}
					/>
					:
					<TeamMembersEdit
						userId={userInfoStore.data.list.id}
						userRole={userInfoStore.data.list.organizations.find(x => x.id === userInfoStore.data.selected.organization).role}
						userData={userData}
						handleUserData={handleUserData}
						applications={applicationsList}
						appsIds={appsIds}
						onCancel={handleModal}
						onSubmit={handleSubmit}
						config={appConfig.TeamMembers.teamMembersEdit}
					/>
				}
			</CustomModal>

		</Container>
	)
}