import {forwardRef, useEffect, useState} from "react";
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatepicker.scss";

import calendarIcon from "../../../assets/images/calendar.svg";

export default function CustomDatepicker(props) {
  const endCorrection =
    props.endCorrection !== undefined && props.endCorrection !== null
      ? props.endCorrection
      : 0;

  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem(`${props.dateKey}SelectedPeriod`)
      ? +localStorage.getItem(`${props.dateKey}SelectedPeriod`)
      : 29
  );
  const [untillToday, setUntillToday] = useState(
    localStorage.getItem(`${props.dateKey}UntillToday`) === "true"
  );

  const newUTCDate = () => {
    const date = new Date();
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());
  }

  const format = (timestamp, resetHours) => {
    const raw = new Date(timestamp);
    if (resetHours) return Math.floor(new Date(raw.setHours(0, 0, 0, 0)).getTime() / 1000);
    else return Math.floor(new Date(raw.setMinutes(0, 0, 0)).getTime() / 1000);
  };

  const handlePresetedPeriod = (e) => {
    let start = new Date(newUTCDate());
    let end = new Date(newUTCDate());
    if (e > 0) {
      start.setDate(end.getDate() - e - endCorrection);
      end.setDate(end.getDate() - endCorrection);
    }
    setSelectedPeriod(Math.round(Math.abs(start - end) / 86400000));
    setUntillToday(true);
    localStorage.setItem(`${props.dateKey}UntillToday`, true);
    localStorage.setItem(
      `${props.dateKey}SelectedPeriod`,
      Math.round(Math.abs(start - end) / 86400000)
    );
    props.changeDate({
      [props.dateFilterKey]: [format(start, e > 0), format(end, false)],
    });
  };

  const handleDate = ([start, end]) => {
    let today = new Date(newUTCDate());
    if (selectedPeriod > 0) today.setDate(today.getDate() - endCorrection);
    if (start && !end) {
      props.changeDate({[props.dateFilterKey]: [format(start, true), end]});
    } else if (!start && end) {
      props.changeDate({[props.dateFilterKey]: [start, format(end, false)]});
    } else if (start && end) {
      setUntillToday(format(today, true) === format(end, false));
      localStorage.setItem(
        `${props.dateKey}UntillToday`,
        format(today, "today") === format(end, false)
      );
      props.changeDate({
        [props.dateFilterKey]: [format(start, true), format(end, false)],
      });
      setSelectedPeriod(Math.round(Math.abs(start - end) / 86400000));
      localStorage.setItem(
        `${props.dateKey}SelectedPeriod`,
        Math.round(Math.abs(start - end) / 86400000)
      );
    }
  };

  useEffect(() => {
    let start = new Date(newUTCDate());
    let end = new Date(newUTCDate());
    if (selectedPeriod > 0) {
      start.setDate(start.getDate() - selectedPeriod - endCorrection);
      end.setDate(end.getDate() - endCorrection);
    }
    if (untillToday || !props.appliedDate) {
      props.changeDate({
        [props.dateFilterKey]: [
          format(start, selectedPeriod > 0),
          format(end, false),
        ],
      });
    } else {
      setSelectedPeriod(
        Math.round(
          Math.abs(props.appliedDate[0] - props.appliedDate[1]) / 86400
        )
      );
    }
  }, []);

  const DatepickerContainer = ({className, children}) => {
    return (
      <Container fluid className={`${className} p-0`}>
        <Row className="m-0">
          <Col className="px-0">{children}</Col>
        </Row>
      </Container>
    );
  };

  const CustomInput = forwardRef(({value, onClick}, ref) => (
    <Button
      variant="secondary"
      className="border-0 rounded-5"
      onClick={onClick}
      ref={ref}
      style={{whiteSpace: "nowrap"}}
    >
      <img src={calendarIcon} width={19} height={18} alt="calendar"/>
      <span className="ps-2">{value}</span>
    </Button>
  ));

  if (props.appliedDate)
    return (
      <Container fluid className="px-0">
        <Row className="bg-secondary rounded-5 m-0">
          <Col className="d-flex align-items-center px-0">
            <Form.Group className="border-end">
              <Form.Select
                value={selectedPeriod === 0 ? 0 : selectedPeriod}
                className="bg-secondary border-0 py-0 rounded-5"
                onChange={(e) => handlePresetedPeriod(+e.target.value)}
              >
                {!untillToday ||
                (selectedPeriod !== 0 &&
                  selectedPeriod !== 6 &&
                  selectedPeriod !== 29 &&
                  selectedPeriod !== 89 &&
                  selectedPeriod !== 364) ? (
                  <option value={selectedPeriod}>
                    {`${untillToday ? "Last" : ""} ${selectedPeriod + 1} ${
                      selectedPeriod > 0 ? "days" : "day"
                    }`}
                  </option>
                ) : null}
                {props.showToday && <option value={0}>Today</option>}
                <option value={6}>Last 7 days</option>
                <option value={29}>Last 30 days</option>
                <option value={89}>Last 90 days</option>
                <option value={364}>Last 365 days</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="px-0">
            <DatePicker
              selectsRange={true}
              startDate={props.appliedDate[0] * 1000}
              endDate={props.appliedDate[1] * 1000}
              onChange={handleDate}
              customInput={<CustomInput/>}
              calendarContainer={DatepickerContainer}
              dateFormat="dd MMM yyyy"
              maxDate={new Date(Date.UTC())}
            />
          </Col>
        </Row>
      </Container>
    );
  else return <></>;
}
