export const EVENTS_DATA = "EVENTS_DATA";
export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENTS_ERROR = "EVENTS_ERROR";

export const EVENTS_FILTERS_DATA = "EVENTS_FILTERS_DATA";
export const EVENTS_FILTERS_LOADED = "EVENTS_FILTERS_LOADED";
export const EVENTS_FILTERS_ERROR = "EVENTS_FILTERS_ERROR";

export const EVENTS_STATUSES_DATA = "EVENTS_STATUSES_DATA";
export const EVENTS_STATUSES_LOADED = "EVENTS_STATUSES_LOADED";
export const EVENTS_STATUSES_ERROR = "EVENTS_STATUSES_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export function eventsReducer(state = defaultState, action) {
	switch (action.type) {
		case EVENTS_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case EVENTS_LOADED:
			return { ...state, loaded: action.payload };
		case EVENTS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function eventsFiltersReducer(state = defaultState, action) {
	switch (action.type) {
		case EVENTS_FILTERS_DATA:
			return { ...state, data: action.payload };
		case EVENTS_FILTERS_LOADED:
			return { ...state, loaded: action.payload };
		case EVENTS_FILTERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function eventsStatusesReducer(state = defaultState, action) {
	switch (action.type) {
		case EVENTS_STATUSES_DATA:
			return { ...state, data: action.payload };
		case EVENTS_STATUSES_LOADED:
			return { ...state, loaded: action.payload };
		case EVENTS_STATUSES_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}