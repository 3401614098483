import { useContext, useState } from "react";
import { Container, Row, Col, Form, Button, Toast } from "react-bootstrap";

import { AppContext } from "../context";
import { ConfigContext } from "../context";

export default function Login() {

	const { appConfig } = useContext(ConfigContext);
	const { setIsAuth } = useContext(AppContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [toast, setToast] = useState({ show: false, error: false, text: "", });

	const clearCache = () => {
		localStorage.clear();
		sessionStorage.clear();
		setToast({ show: true, error: false, text: "Cache have been successfully cleared" })
	}

	const logIn = async () => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/me/login`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							email,
							password
						})
					}
				)).json();
			if (response._code === 200) {
				localStorage.setItem("userToken", response._data.user_token);
				setIsAuth(true);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			setToast({ show: true, error: true, text: err.message })
		}
	}

	const handleLogIn = (e) => {
		e.preventDefault();
		if (email !== "" && password !== "") {
			logIn();
		}
	}

	return (
		<Container fluid className="vh-100 d-flex flex-column justify-content-center bg-light px-0">

			<Row className="position-absolute w-100 top-0 m-0">
				<Col className="px-0">
					<Toast
						className={`${toast.error ? "bg-danger" : "bg-success"} rounded-0 border-0 shadow-none w-100 px-0`}
						onClose={() => setToast({...toast, show: false, text: "", })}
						show={toast.show}
						delay={3000}
						autohide
					>
						<Toast.Body className={`${toast.error ? "text-dark-red" : "text-dark-green"} SFProDisplay-Bold text-center py-4`}>
							{toast.text}
						</Toast.Body>
					</Toast>
				</Col>
			</Row>

			<Row className="m-0">
				<Col className="pb-4 px-0">
					<h3 className="SFProDisplay-Bold text-center text-uppercase m-0">
						{appConfig.Login.wording.text_1}
					</h3>
				</Col>
			</Row>

			<Row className="d-flex justify-content-center align-content-center w-100 m-0 px-3">
				<Col lg={4} className="bg-white rounded-5 shadow p-3 p-lg-5">

					<Form onSubmit={(e) => handleLogIn(e)}>

						<Row className="m-0">
							<Col className="px-0">
								<h4 className="SFProDisplay-Bold text-center m-0">
									{appConfig.Login.wording.text_2}
								</h4>
							</Col>
						</Row>

						<Form.Group className="pt-4" controlId="formEmail">
							<Form.Label>
								{appConfig.Login.wording.text_3}
							</Form.Label>
							<Form.Control className="w-100" type="email" onChange={(e) => setEmail(e.target.value)} />
						</Form.Group>

						<Form.Group className="pt-4" controlId="formPassword">
							<Form.Label>
								{appConfig.Login.wording.text_4}
							</Form.Label>
							<Form.Control className="w-100" type="password" onChange={(e) => setPassword(e.target.value)} />
						</Form.Group>

						<Row className="pt-4 m-0">
							<Col className="px-0">
								<Button variant="primary" className="w-100 rounded-5 py-2" type="submit" disabled={!email || !password}>
									{appConfig.Login.wording.text_5}
								</Button>
							</Col>
						</Row>

						<Row className="pt-5 m-0">
							<Col className="text-center px-0">
								<Button variant="link" className="text-decoration-none text-primary" onClick={clearCache}>
									Clear cache
								</Button>
							</Col>
						</Row>

					</Form>

				</Col>
			</Row>

		</Container>
	)
}