import { Container, Row, Col, Table } from "react-bootstrap";

export default function CustomerInstalls(props) {

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}).format(timestamp * 1000);
	}

	return (
		<Container fluid className="px-0">

			<Row className="pt-3 m-0">
				<Col className="px-0">
					<Table responsive>
						<thead>
							<tr className="border-top border-secondary">
								{Object.keys(props.data.rows).map(key =>
									<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4" key={key}>
										{props.data.rows[key]}
									</th>
								)}
							</tr>
						</thead>

						<tbody className="border-top-0">
							{props.data.values.map(val =>
								<tr className="border-top border-secondary" key={val.anonymous_id}>
									{Object.keys(props.data.rows).map(key => {
										if (key === "platform") return (
											<td className="white-space-nowrap ps-0 pe-4" key={key}>
												{props.platforms.find(x => x.id === val.platform).name}
											</td>
										)
										else if (key === "created_at") return (
											<td className="white-space-nowrap ps-0 pe-4" key={key}>
												{timestampToDate(val[key])}
											</td>
										)
										else return (
											<td className="white-space-nowrap ps-0 pe-4" key={key}>
												{val[key]}
											</td>
										)
									})}
								</tr>
							)}
						</tbody>

					</Table>
				</Col>
			</Row>

		</Container>
	);
}