import { useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";

import CustomDropdown from "../components/UI/CustomDropdown";
import dots from "../assets/images/dots.svg";

export default function TeamMembersList(props) {

	const [filterRole, setFilterRole] = useState(0);

	const getApps = (user) => {
		if (user.role === 1 || (props.userRole === 1 && user.application_ids.length === props.applicationsList.length)) {

			return (
				<Col xs="auto" className="d-flex align-items-center pt-1 pt-lg-0 py-1 px-0">
					{props.config.wording.text_9}
				</Col>
			)

		} else if (props.userRole === 1 || user.user_id === props.userId) {

			if (user.application_ids.length > 3) return (
				<>
					{user.application_ids.slice(0, 3).map((app, i) =>
						<Col xs="auto" className="pt-1 pt-lg-0 ps-0 pe-2" key={i}>
							<img src={props.applicationsList.find(x => x.id === app).icon_url} className="team-app-icon rounded-5" alt="app" />
						</Col>
					)}
					<Col xs="auto" className="d-flex align-items-center pt-1 pt-lg-0 ps-0 pe-2">
						{props.config.wording.text_10} {user.application_ids.length - 3} {props.config.wording.text_11}
					</Col>
				</>
			)
			else return user.application_ids.map((app, i) =>
				<Col xs="auto" className="pt-1 pt-lg-0 ps-0 pe-2" key={i}>
					<img src={props.applicationsList.find(x => x.id === app).icon_url} className="team-app-icon rounded-5" alt="app" />
				</Col>
			)

		} else {

			return (
				<Col xs="auto" className="d-flex align-items-center pt-1 pt-lg-0 py-1 px-0">
					{user.application_ids.length} apps
				</Col>
			)

		}
	}

	return (
		<Container fluid className="px-0">
			<Row className="pt-4 m-0">

				<Col lg={2} className="px-0">
					<Row className="m-0">
						<Col className="px-2">
							<p className="SFProDisplay-Semibold m-0">
								{props.config.wording.text_1}
							</p>
						</Col>
					</Row>
					<Row className="pt-2 m-0">
						<Col className="px-0">
							<Button
								variant={`${filterRole === 0 ? "secondary" : "transparent"}`}
								className="w-100 text-start py-1 px-2"
								onClick={() => setFilterRole(0)}
							>
								{props.config.wording.text_2}
							</Button>
						</Col>
					</Row>
					<Row className="pt-2 m-0">
						<Col className="px-0">
							<Button
								variant={`${filterRole === 1 ? "secondary" : "transparent"}`}
								className="w-100 text-start py-1 px-2"
								onClick={() => setFilterRole(1)}
							>
								{props.config.wording.text_3}
							</Button>
						</Col>
					</Row>
					<Row className="pt-2 m-0">
						<Col className="px-0">
							<Button
								variant={`${filterRole === 2 ? "secondary" : "transparent"}`}
								className="w-100 text-start py-1 px-2"
								onClick={() => setFilterRole(2)}
							>
								{props.config.wording.text_4}
							</Button>
						</Col>
					</Row>
				</Col>

				<Col className="pt-4 px-0">
					<Table responsive>

						<thead>
							<tr>
								<th className="opacity-50 fw-normal border-0 custom-cell">
									{props.config.wording.text_5}
								</th>
								<th className="opacity-50 fw-normal border-0 custom-cell">
									{props.config.wording.text_6}
								</th>
								<th className="opacity-50 fw-normal border-0 custom-cell">
									{props.config.wording.text_7}
								</th>
								<th className="opacity-50 fw-normal border-0 custom-cell">
									{props.config.wording.text_8}
								</th>
								<th className="opacity-50 fw-normal border-0 custom-cell"></th>
							</tr>
						</thead>

						<tbody className="border-top-0">

							{props.usersList.map((user, i) => (filterRole === 0 || user.role === filterRole) && (
								<tr key={i}>

									<td className="cursor-pointer custom-cell">
										{user.name}
									</td>
									<td className="cursor-pointer custom-cell">
										{user.email}
									</td>
									<td className="cursor-pointer custom-cell">
										{props.rolesList.find(x => x.id === user.role).name}
									</td>
									<td className="cursor-pointer custom-cell">
										<Row className="m-0">
											{getApps(user)}
										</Row>
									</td>
									<td className="cursor-pointer custom-cell">
										{(props.userRole === 1 || user.user_id === props.userId) &&
											<Container>
												<Row className="pt-2 pb-1 m-0">
													<Col lg="auto" className="px-0">
														<Button variant="link" className="text-decoration-none text-start text-black w-100" onClick={() => props.handleModalContent("edit", user)}>
															{props.config.wording.text_12}
														</Button>
													</Col>
													<Col lg="auto" className="px-0">
														<Button variant="link" className="text-decoration-none text-start text-dark-red w-100" onClick={() => props.handleModalContent("delete", user)}>
															{props.config.wording.text_13}
														</Button>
													</Col>
												</Row>
											</Container>
										}
									</td>

								</tr>
							))}
						</tbody>

					</Table>

					{/* <Container fluid className="px-0">
						<Row className="p-2 m-0">
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_5}
							</Col>
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_6}
							</Col>
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_7}
							</Col>
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_8}
							</Col>
						</Row>
						{props.usersList.map((user, i) => (filterRole === 0 || user.role === filterRole) && (
							<Row className="d-flex align-items-center border-top border-1 border-secondary p-2 m-0" key={i}>

								<Col className="px-0">
									{user.name}
								</Col>

								<Col className="px-0">
									{user.email}
								</Col>

								<Col className="px-0">
									{props.rolesList.find(x => x.id === user.role).name}
								</Col>

								<Col className="px-0">
									<Row className="m-0">

										<Col className="px-0">
											<Row className="m-0">
												{getApps(user)}
											</Row>
										</Col>

										<Col xs="auto" className="px-0">
											{(props.userRole === 1 || user.user_id === props.userId) &&
												<CustomDropdown noArrow={true} buttonText={<img src={dots} className="img-fluid" alt="dots" />}>
													<Row className="pt-2 pb-1 m-0">
														<Col>
															<Button variant="link" className="text-decoration-none text-start text-black w-100" onClick={() => props.handleModalContent("edit", user)}>
																{props.config.wording.text_12}
															</Button>
														</Col>
													</Row>
													<Row className="pt-1 pb-2 m-0">
														<Col>
															<Button variant="link" className="text-decoration-none text-start text-dark-red w-100" onClick={() => props.handleModalContent("delete", user)}>
																{props.config.wording.text_13}
															</Button>
														</Col>
													</Row>
												</CustomDropdown>
											}
										</Col>

									</Row>
								</Col>

							</Row>
						))}
					</Container> */}
				</Col>

			</Row>
		</Container>
	);
}