import { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

export default function IntegrationsFirebase(props) {

	useEffect(() => {
		props.handleTypesSettings(props.integtationData.types.map(type => { return { [type.id]: type.enabled === 1 } }));
	}, [])

	return (
		<Container className="pt-4 pb-5 px-3 px-lg-5">

			<Row className="d-flex align-items-center m-0">
				<Col className="px-0">
					<h2 className="SFProDisplay-Bold m-0">
						<span className="text-capitalize">{props.config.wording.text_7}</span> {props.config.wording.text_8}
					</h2>
				</Col>
			</Row>

			<Row className="d-flex align-items-center pt-4 m-0">
				<Col className="px-0">
					<h5 className="SFProDisplay-Bold m-0">
						{props.config.wording.text_1}
					</h5>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col lg={4} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
						{props.config.wording.text_2}
						</Form.Label>
						<Form.Control
							type="text"
							placeholder={props.config.wording.text_3}
							defaultValue={props.integtationData.firebase_key}
							key={props.integtationData.firebase_key}
							className="mb-2"
							onChange={(e) => props.handleIntegrationsSettings("firebase_key", e.target.value)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="py-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={props.handleSubmitIntegrationSettings}>
						{props.config.wording.text_4}
					</Button>
				</Col>
			</Row>

			<Row className="border-top border-1 border-secondary" />

			<Row className="pt-4 m-0">
				<Col className="px-0">
					{props.integtationData.types.map((type, i) =>
						<Form.Group key={i}>
							<Form.Label className="SFProDisplay-Semibold">
								{props.config.wording.text_5}
							</Form.Label>
							<Form.Check
								label={type.name}
								defaultChecked={type.enabled === 1}
								onChange={(e) => props.handleTypesSettings({ [type.id]: e.target.checked })}
							/>
						</Form.Group>
					)}
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={props.handleSubmitTypesSettings}>
						{props.config.wording.text_6}
					</Button>
				</Col>
			</Row>

		</Container>
	)
}