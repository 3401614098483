export const INSTALLS_DATA = "INSTALLS_DATA";
export const INSTALLS_LOADED = "INSTALLS_LOADED";
export const INSTALLS_ERROR = "INSTALLS_ERROR";

export const INSTALLS_FILTERS_DATA = "INSTALLS_FILTERS_DATA";
export const INSTALLS_FILTERS_LOADED = "INSTALLS_FILTERS_LOADED";
export const INSTALLS_FILTERS_ERROR = "INSTALLS_FILTERS_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export function installsReducer(state = defaultState, action) {
	switch (action.type) {
		case INSTALLS_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case INSTALLS_LOADED:
			return { ...state, loaded: action.payload };
		case INSTALLS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function installsFiltersReducer(state = defaultState, action) {
	switch (action.type) {
		case INSTALLS_FILTERS_DATA:
			return { ...state, data: action.payload };
		case INSTALLS_FILTERS_LOADED:
			return { ...state, loaded: action.payload };
		case INSTALLS_FILTERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}