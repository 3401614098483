import { Container, Row, Col, Badge } from "react-bootstrap";
import { BarChart, Bar, Tooltip, ReferenceLine, ResponsiveContainer, XAxis } from "recharts";
import { format, fromUnixTime } from "date-fns";

export default function CustomBarChart(props) {

	return (
		<Container fluid className="border-bottom border-end border-secondary p-0 p-lg-3">
			<Row className="d-flex justify-content-between m-0">
				<Col className="d-flex align-items-center px-0">
					<span className="text-dark pe-2">
						{props.name}
					</span>
					{props.dynamics &&
						<Badge
							bg={props.dynamics > 0 ? "success" : "danger"}
							className={`${props.dynamics > 0 ? "text-dark-green" : "text-dark-red"} SFProDisplay-Medium fw-normal`}>
							{props.dynamics > 0 && "+"}{props.dynamics} %
						</Badge>
					}
				</Col>
			</Row>
			<Row className="d-flex justify-content-between m-0">
				<Col className="px-0">
					<p className="SFProDisplay-Medium m-0">{props.sum} {props.currency}</p>
				</Col>
			</Row>
			<Row className="m-0">
				<Col className="border-bottom border-2 w-100 px-0" style={{ height: 100 }}>
					<ResponsiveContainer>
						{props.negativeKey
							?
							<BarChart stackOffset="sign" data={props.data}>
								<Tooltip
									labelFormatter={(label) => format(fromUnixTime(label), "MMM dd")}
									cursor={{ fill: '#eaf1f9' }}
									formatter={(value) => [value, `${props.currency}`]}
								/>
								<XAxis
									type="number"
									scale="time"
									ticks={props.data.map(a => a[props.timestampKey])}
									tickFormatter={(tick) => format(fromUnixTime(tick), "MMM dd")}
									domain={[props.data[0][props.timestampKey], props.data[props.data.length - 1][props.timestampKey]]}
									dataKey={props.timestampKey}
									hide
								/>
								<ReferenceLine y={0} stroke="#000" />
								<Bar dataKey={props.dataKey} fill="#2D671F" stackId="stack" />
								<Bar dataKey={props.negativeKey} fill="#992E2E" stackId="stack" />
							</BarChart>
							:
							<BarChart data={props.data}>
								<Tooltip
									labelFormatter={(label) => format(fromUnixTime(label), "MMM dd")}
									formatter={(value) => [value, `${props.currency}`]}
									cursor={{ fill: '#eaf1f9' }}
								/>
								<XAxis
									type="number"
									scale="time"
									ticks={props.data.map(a => a[props.timestampKey])}
									tickFormatter={(tick) => format(fromUnixTime(tick), "MMM dd")}
									domain={[props.data[0][props.timestampKey], props.data[props.data.length - 1][props.timestampKey]]}
									dataKey={props.timestampKey}
									hide
								/>
								<Bar dataKey={props.dataKey} fill="#2C71C3" />
							</BarChart>
						}
					</ResponsiveContainer>
				</Col>
			</Row>
			<Row className="d-flex justify-content-between pt-2 m-0">
				<Col className="px-0">
					<p className="text-dark fs-6 m-0">{format(fromUnixTime(props.data[props.data.length - 1][props.timestampKey]), "MMM dd")}</p>
				</Col>
				<Col className="text-end px-0">
					<p className="text-dark fs-6 m-0">{format(fromUnixTime(props.data[0][props.timestampKey]), "MMM dd")}</p>
				</Col>
			</Row>
		</Container >
	);
}