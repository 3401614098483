import {
	USERS_DATA,
	USERS_LOADED,
	USERS_ERROR,
	ROLES_DATA,
	ROLES_LOADED,
	ROLES_ERROR
} from "../reducers/teamMembersReducer";

export const getUsersData = (url, params) => dispatch => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: USERS_DATA, payload: data._data });
				dispatch({ type: USERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: USERS_ERROR, payload: err.message });
		});
}

export const getRolesData = (url) => (dispatch) => {
	fetch(url)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: ROLES_DATA, payload: data._data.roles });
				dispatch({ type: ROLES_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: ROLES_ERROR, payload: err.message });
		});
}