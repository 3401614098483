export const COHORTS_DATA = "COHORTS_DATA";
export const COHORTS_LOADED = "COHORTS_LOADED";
export const COHORTS_ERROR = "COHORTS_ERROR";

export const ASA_DATA = "ASA_DATA";
export const ASA_LOADED = "ASA_LOADED";
export const ASA_ERROR = "ASA_ERROR";

export const COHORTS_FILTERS_DATA = "COHORTS_FILTERS_DATA";
export const COHORTS_FILTERS_LOADED = "COHORTS_FILTERS_LOADED";
export const COHORTS_FILTERS_ERROR = "COHORTS_FILTERS_ERROR";

export const ASA_FILTERS_DATA = "ASA_FILTERS_DATA";
export const ASA_FILTERS_LOADED = "ASA_FILTERS_LOADED";
export const ASA_FILTERS_ERROR = "ASA_FILTERS_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export function cohortsReducer(state = defaultState, action) {
	switch (action.type) {
		case COHORTS_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case COHORTS_LOADED:
			return { ...state, loaded: action.payload };
		case COHORTS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function asaReducer(state = defaultState, action) {
	switch (action.type) {
		case ASA_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case ASA_LOADED:
			return { ...state, loaded: action.payload };
		case ASA_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function cohortsFiltersReducer(state = defaultState, action) {
	switch (action.type) {
		case COHORTS_FILTERS_DATA:
			return { ...state, data: action.payload };
		case COHORTS_FILTERS_LOADED:
			return { ...state, loaded: action.payload };
		case COHORTS_FILTERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function asaFiltersReducer(state = defaultState, action) {
	switch (action.type) {
		case ASA_FILTERS_DATA:
			return { ...state, data: action.payload };
		case ASA_FILTERS_LOADED:
			return { ...state, loaded: action.payload };
		case ASA_FILTERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}