import {
	CUSTOMERS_DATA,
	CUSTOMERS_LOADED,
	CUSTOMERS_ERROR,
	CUSTOMERS_FILTERS_DATA,
	CUSTOMERS_FILTERS_LOADED,
	CUSTOMERS_FILTERS_ERROR,
	CUSTOMERS_STATUSES_DATA,
	CUSTOMERS_STATUSES_LOADED,
	CUSTOMERS_STATUSES_ERROR
} from "../reducers/customersReducer";

const filtersToURLparams = (filters) => {
	let URLparams = "";
	for (let i = 0, keys = Object.keys(filters); i < keys.length; ++i) {
		if (Array.isArray(filters[keys[i]]))
			for (const arg of filters[keys[i]]) URLparams += `&${keys[i]}=${arg}`;
		else URLparams += `&${keys[i]}=${filters[keys[i]]}`;
	}
	return URLparams;
}

export const getCustomersData = (url, params) => dispatch => {

	dispatch({ type: CUSTOMERS_LOADED, payload: false });

	fetch(`${url}?${filtersToURLparams(params)}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: CUSTOMERS_DATA, payload: data._data });
				dispatch({ type: CUSTOMERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: CUSTOMERS_ERROR, payload: err.message });
		});
}

export const getCustomresFiltersData = (url, params) => (dispatch) => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: CUSTOMERS_FILTERS_DATA, payload: data._data.filters });
				dispatch({ type: CUSTOMERS_FILTERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: CUSTOMERS_FILTERS_ERROR, payload: err.message });
		});
}

export const getCustomersStatusesData = (url, params) => (dispatch) => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: CUSTOMERS_STATUSES_DATA, payload: data._data.statuses });
				dispatch({ type: CUSTOMERS_STATUSES_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: CUSTOMERS_STATUSES_ERROR, payload: err.message });
		});
}