import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Dropdown, Button } from "react-bootstrap";

import { AppContext } from "../context";
import { setUserInfoData } from "../actions/userInfoActions";
import userAvatar from "../assets/images/user.svg";
import checked from "../assets/images/checked.svg";

export default function UserActions(props) {

	const { setLoading } = useContext(AppContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const [userRole, setUserRole] = useState(null)
	const _user_token = localStorage.getItem("userToken");

	const submitUserData = async (id) => {
		setLoading(true);
		try {
			const response = await (
				await fetch(`/api/v1/frontend/users/edit`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							user_id: userInfoStore.data.list.id,
							organization_id: userInfoStore.data.selected.organization,
							selected_organization_id: id
						})
					})
			).json();
			if (response._code === 200) {
				dispatch(setUserInfoData({
					selected: {
						organization: id,
						application: +localStorage.getItem(`org-${id}SelectedApp`) || 0
					}
				}));
				setLoading(false);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
		}
	}

	const handleLogout = () => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
	}

	useEffect(() => {
		setUserRole(userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).role)
	}, [userInfoStore])

	return (
		<Dropdown>
			<Dropdown.Toggle className="bg-transparent text-start text-black border-0 p-0">
				<p className="SFProDisplay-Semibold m-0">
					{userInfoStore.data.list.name}
				</p>
				<p className="opacity-50 m-0">
					{userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).name}
				</p>
			</Dropdown.Toggle>
			<Dropdown.Menu className="rounded-5 p-0">

				<Dropdown.Item as="li" className="py-2 px-0">
					<Row className="d-flex align-items-center m-0 py-2 px-3">
						<Col xs={1} lg="auto" className="px-0">
							<img
								src={userAvatar}
								className="img-fluid"
								alt="avatar"
							/>
						</Col>
						<Col xs={1} lg="auto" className="ps-lg-3 pe-0">
							<p className="white-space-nowrap m-0">
								{userInfoStore.data.list.email}
							</p>
							<Link className="text-decoration-none text-primary" to="/account">
								{props.config.wording.text_1}
							</Link>
						</Col>
					</Row>
					<Row className="border-bottom border-1 border-secondary mx-2" />
				</Dropdown.Item>

				{userInfoStore.data.list.organizations.length > 1 &&
					<>
						{userInfoStore.data.list.organizations.map((org, i) => (
							<Dropdown.Item as="li" className="py-2 px-0" key={i}>
								<Row className="d-flex align-items-center justify-content-between cursor-pointer m-0" onClick={() => submitUserData(org.id)}>
									<Col className="px-2">
										<p className="m-0">
											{org.name}
										</p>
									</Col>
									{org.id === userInfoStore.data.selected.organization &&
										<Col xs="auto" className="px-2">
											<img src={checked} className="img-fluid" alt="checked" />
										</Col>
									}
								</Row>
							</Dropdown.Item>
						))}
						<Row className="border-bottom border-1 border-secondary mx-2" />
					</>
				}

				<Dropdown.Item as="li" className="p-2">
					<Link className="text-decoration-none text-black" to="/organizations">
						{props.config.wording.text_2}
					</Link>
				</Dropdown.Item>
				<Row className="border-bottom border-1 border-secondary mx-2" />

				{userRole === 1 &&
					<>
						<Dropdown.Item as="li" className="p-2">
							<Link className="text-decoration-none text-black" to="/team">
								{props.config.wording.text_3}
							</Link>
						</Dropdown.Item>
						<Row className="border-bottom border-1 border-secondary mx-2" />
					</>
				}

				<Dropdown.Item as="li" className="p-2">
					<Button variant="link" className="text-decoration-none text-dark-red p-0" onClick={handleLogout}>
						{props.config.wording.text_4}
					</Button>
				</Dropdown.Item>

			</Dropdown.Menu>
		</Dropdown>
	);
}