import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

export default function SettingsPaypal(props) {

	const initialState = {
		test_client_identifier: "",
		test_secret: "",
		live_client_identifier: "",
		live_secret: ""
	}

	const [settingsData, setSettingsData] = useState(initialState);

	const handleSettingsData = (key, value) => {
		setSettingsData({ ...settingsData, [key]: value });
	}

	useEffect(() => {
		if (props.settingsData) setSettingsData(props.settingsData);
		else setSettingsData(initialState);
	}, [props.settingsData])

	return (
		<Container fluid className="px-0">

			<Row className="pb-4 m-0">
				<Col lg={5} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_1}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.test_client_identifier}
							onChange={(e) => handleSettingsData("test_client_identifier", e.target.value)}
							placeholder={props.config.wording.text_2}
						/>
					</Form.Group>

					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_3}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.test_secret}
							onChange={(e) => handleSettingsData("test_secret", e.target.value)}
							placeholder={props.config.wording.text_4}
						/>
					</Form.Group>

					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_5}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.live_client_identifier}
							onChange={(e) => handleSettingsData("live_client_identifier", e.target.value)}
							placeholder={props.config.wording.text_6}
						/>
					</Form.Group>

					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_7}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.live_secret}
							onChange={(e) => handleSettingsData("live_secret", e.target.value)}
							placeholder={props.config.wording.text_8}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="d-flex align-items-center justify-content-between border-top border-secondary pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={() => props.updateSettings("edit_paypal_settings", settingsData)}>
						{props.settingsData ? props.config.wording.text_9 : props.config.wording.text_10}
					</Button>
				</Col>
				{props.settingsData &&
					<Col xs="auto" className="px-4">
						<Button variant="link" className="text-decoration-none text-dark-red p-0" onClick={() => props.handleModal("delete_paypal_settings")}>
							{props.config.wording.text_11}
						</Button>
					</Col>
				}
			</Row>

		</Container>
	);
}