import { Container, Row, Col, Button, } from "react-bootstrap";

import closeIcon from "../assets/images/close.svg"

export default function AppliedFilters(props) {

	const format = (string) => {
		return string.replace(/\[]/, '').replace(/(_min)/, '').replace(/(_max)/, '');
	}

	const list = (string, num) => {
		if (num > 0) return `, ${string}`
		else return string
	}

	return (
		<Container fluid className="px-0">
			<Row className="m-0">
				{Object.keys(props.appliedFilters).map((filter, i) => (
					!props.exceptFilters.includes(filter) &&
					<Col xs="auto" className="ps-0 pe-2 pt-2" key={i}>
						<div className="d-flex align-items-center border border-2 border-primary rounded-5 py-1 px-2">
							<span className="text-primary m-0">
								{props.availableFilters[format(filter)].name}
								{filter.match(/(_min)/) ? " Min" : filter.match(/(_max)/) ? " Max" : ""}
								:&nbsp;
								{typeof props.appliedFilters[filter] === "number"
									? `${props.appliedFilters[filter]}`
									: props.availableFilters[format(filter)].type === "boolean"
										? props.appliedFilters[filter] === '1' ? "True" : "False"
										: props.appliedFilters[filter].map((value, i) => {
											if (props.availableFilters[format(filter)].type === "assoc list")
												return list(props.availableFilters[format(filter)].values[value], i)
											else return list(value, i)
										})}
							</span>
							<Button variant="link" className="d-flex align-items-center py-0 ps-1 pe-0" onClick={() => props.handleRemoveFilter(filter)}>
								<img src={closeIcon} className="img-fluid" alt="close" />
							</Button>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	)
}