import { Row, Col, Accordion, Button, Form } from "react-bootstrap";

import CustomDropdown from "./UI/CustomDropdown";
import filterIcon from "../assets/images/filter.svg";

export default function Filters(props) {

	const getFiltersByType = (key, type, values) => {
		if (type === "list") {
			return (
				values.map((li, i) => (
					<div className="py-1 px-3" key={i}>
						<Form.Check
							label={li}
							checked={(props.appliedFilters[`${key}[]`] && props.appliedFilters[`${key}[]`].includes(li)) || false}
							onChange={() => props.handleAppliedFilters(type, `${key}[]`, li)}
						/>
					</div>
				))
			);
		} else if (type === "boolean") {
			return (
				<div className="d-flex align-items-center py-1 px-3">
					<Form>
						<Form.Check
							type="radio"
							id={`true_${key}`}
							checked={props.appliedFilters[key] === '1'}
							onChange={() => props.handleAppliedFilters(type, key, '1')}
							label="True"
						/>
						<Form.Check
							type="radio"
							id={`false_${key}`}
							checked={props.appliedFilters[key] === '0'}
							onChange={() => props.handleAppliedFilters(type, key, '0')}
							label="False"
						/>
					</Form>
				</div>
			);
		} else if (type === "assoc list") {
			return (
				Object.keys(values).map((li, i) => (
					<div className="d-flex align-items-center py-1 px-3" key={i}>
						<Form.Check
							id={`${values[li].replace(/ /g, '_').toLowerCase()}_${i}`}
							checked={(props.appliedFilters[`${key}[]`] && props.appliedFilters[`${key}[]`].includes(`${li}`)) || false}
							onChange={() => props.handleAppliedFilters(type, `${key}[]`, li)}
						/>
						<Form.Label htmlFor={`${values[li].replace(/ /g, '_').toLowerCase()}_${i}`} className="m-0 ps-1">
							{values[li]}
						</Form.Label>
					</div>
				))
			);
		} else if (type === "num_range") {
			return (
				<div className="py-1 px-3">
					<Form.Group className="">
						<Form.Label>
							Values (min - max)
						</Form.Label>
						<Row className="d-flex align-items-center m-0">
							<Col className="px-0">
								<Form.Control
									type="number"
									min={0}
									value={props.appliedFilters[`${key}_min`] || ""}
									onChange={(e) => props.handleAppliedFilters("num_range", `${key}_min`, +e.target.value)}
								/>
							</Col>
							<Col xs="auto" className="px-2">
								-
							</Col>
							<Col className="px-0">
								<Form.Control
									type="number"
									min={0}
									value={props.appliedFilters[`${key}_max`] || ""}
									onChange={(e) => props.handleAppliedFilters("num_range", `${key}_max`, +e.target.value)}
								/>
							</Col>
						</Row>
					</Form.Group>
				</div>
			);
		}
	}

	return (
		<CustomDropdown
			align={props.align}
			buttonText={
				<div className="d-flex align-items-baseline">
					<img src={filterIcon} className="img-fluid" alt="filter" />
					<span className="ps-2">
						Filter
					</span>
				</div>
			}
			dropdownItem={
				<div className="p-3">
					<Button className="w-100 rounded-5" onClick={props.applyFilters}>
						Apply
					</Button>
				</div>
			}
		>
			<Accordion defaultActiveKey={Object.keys(props.availableFilters).length < 3 ? 1 : null}>
				{Object.keys(props.availableFilters).map((key, i) => props.availableFilters[key].type !== "date" && (
					<Accordion.Item
						className={props.availableFilters.length === i + 1 ? "border-0" : "border-top-0 border-start-0 border-end-0"}
						eventKey={i}
						key={i}
					>
						<Accordion.Header>
							<p className="m-0 d-inline w-100 pe-5 white-space-nowrap">
								{props.availableFilters[key].name}
							</p>
						</Accordion.Header>
						<Accordion.Body className="py-1 px-0">
							{getFiltersByType(key, props.availableFilters[key].type, props.availableFilters[key].values)}
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
		</CustomDropdown >
	)
}