import { Container, Row, Col, Table, Badge } from "react-bootstrap";

export default function CustomerCardApplePayments(props) {

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}).format(timestamp * 1000);
	}

	const getColor = (code, val) => {
		if (code === 0) {

			if (val === true) return "True"
			else if (val === false) return "False"
			else return <></>

		} else if (code === 1) {

			if (val === true) return (
				<Badge pill bg="success" className="text-dark-green fw-normal text-capitalize m-0">
					True
				</Badge>
			)
			else if (val === false) return (
				<Badge pill bg="danger" className="text-dark-red fw-normal text-capitalize m-0">
					False
				</Badge>
			)
			else return <></>

		} else if (code === 2) {

			if (val === true) return (
				<Badge pill bg="danger" className="text-dark-red fw-normal text-capitalize m-0">
					True
				</Badge>
			)
			else if (val === false) return "False"
			else return <></>

		} else if (code === 3) {

			if (val === true) return (
				<Badge pill bg="info" className="text-primary fw-normal text-capitalize m-0">
					True
				</Badge>
			)
			else if (val === false) return "False"
			else return <></>

		} else if (code === 4) {

			if (val === true) return (
				<Badge pill bg="warning" className="text-dark-yellow fw-normal text-capitalize m-0">
					True
				</Badge>
			)
			else if (val === false) return "False"
			else return <></>

		}
	}

	return (
		<Container fluid className="px-0">

			<Row className="m-0">
				<Col className="px-0">
					<h6 className="SFProDisplay-Bold m-0">
						{props.title}
					</h6>
				</Col>
			</Row>

			<Row className="pt-3 m-0">
				<Col className="px-0">
					<Table responsive>

						<thead>
							<tr className="border-top border-secondary">
								{Object.keys(props.data.rows).map(key =>
									<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4" key={key}>
										{props.data.rows[key]}
									</th>
								)}
							</tr>
						</thead>

						<tbody className="border-top-0">
							{props.data.values.map(val =>
								<tr className="border-top border-secondary" key={val.id}>
									{Object.keys(props.data.rows).map(key => {
										if (key === "purchase_date"
											|| key === "expires_date"
											|| key === "first_purchase_date"
											|| key === "start_time"
											|| key === "expire_time"
											|| key === "period_start"
											|| key === "period_end"
											|| key === "created_at"
											|| key === "start_date"
										) return (
											<td className="white-space-nowrap ps-0 pe-4" key={key}>
												{timestampToDate(val[key])}
											</td>
										)
										else if (props.data.colors[key]) return (
											<td className="white-space-nowrap ps-0 pe-4" key={key}>
												{getColor(props.data.colors[key], val[key])}
											</td>
										)
										else return (
											<td className="white-space-nowrap ps-0 pe-4" key={key}>
												{val[key]}
											</td>
										)
									})}
								</tr>
							)}
						</tbody>

					</Table>
				</Col>
			</Row>

		</Container>
	);
}