import {useEffect, useState, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col, Table, Badge, InputGroup, Form} from "react-bootstrap";

import {ConfigContext} from "../context";
import {
  getEventsData,
  getEventsFiltersData,
  getEventsStatusesData
} from "../actions/eventsActions";
import CustomPagination from "../components/CustomPagination";
import CustomDatepicker from "../components/UI/CustomDatepicker";
import Loader from "../components/UI/Loader";
import Filters from "../components/Filters";
import AppliedFilters from "../components/AppliedFilters";
import Notification from "../components/UI/Notification";
import searchIcon from "../assets/images/search.svg";

const newUTCDate = () => {
  const date = new Date();
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
    date.getUTCDate(), date.getUTCHours(),
    date.getUTCMinutes(), date.getUTCSeconds());
}

const initialDateFilter = () => {
  let start = new Date(newUTCDate());
  start.setDate(start.getDate() - 30);
  let end = new Date();
  return {[`created_at[]`]: [Math.floor(start.getTime() / 1000), Math.floor(end.getTime() / 1000)]};
}

export default function EventLog() {

  const {appConfig} = useContext(ConfigContext);
  const dispatch = useDispatch();
  const userInfoStore = useSelector(state => state.userInfo);
  const eventsStore = useSelector(state => state.events);
  const filtersStore = useSelector(state => state.eventsFilters);
  const statusesStore = useSelector(state => state.eventsStatuses);
  const [tableLoading, setTableLoading] = useState(true);
  const savedFilters = JSON.parse(localStorage.getItem("eventLogAppliedFilters")) || initialDateFilter();
  const [notification, setNotification] = useState({show: false, title: "", text: "", time: ""});
  const [appliedFilters, setAppliedFilters] = useState(savedFilters);
  const _user_token = localStorage.getItem("userToken");
  const [initialized, setInitialized] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);

  const statusType = {
    "-2": "danger",
    "-1": "warning",
    "0": "info",
    "1": "success"
  }

  const statusColor = {
    "-2": "text-dark-red",
    "-1": "text-dark-yellow",
    "0": "text-primary",
    "1": "text-dark-green"
  }

  const updateEventsList = (params = {}) => {
    dispatch(getEventsData(`/api/v1/frontend/events/list`, {
      _user_token,
      application_id: userInfoStore.data.selected.application, ...params
    }));
  }

  const handleAppliedFilters = (type, key, value) => {
    if (type === "list" || type === "assoc list") {

      if (key in appliedFilters) {
        if (appliedFilters[key].includes(value)) {
          if (appliedFilters[key].length > 1) {
            setAppliedFilters({...appliedFilters, [key]: appliedFilters[key].filter(x => x !== value)});
          } else {
            setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
          }
        } else {
          setAppliedFilters({...appliedFilters, [key]: [...appliedFilters[key], value]});
        }
      } else {
        setAppliedFilters({...appliedFilters, [key]: [value]});
      }

    } else if (type === "boolean") {

      setAppliedFilters({...appliedFilters, [key]: value});

    } else if (type === "num_range") {

      if (!value) {
        setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
      } else {
        setAppliedFilters({...appliedFilters, [key]: value});
      }

    }
  }

  const handleSearch = (e) => {
    if (e.target.value.length > 5) {
      setAppliedFilters({...appliedFilters, event_search: e.target.value});
      updateEventsList({...appliedFilters, event_search: e.target.value}, "handleSearch");
    } else if (e.target.value.length === 0) {
      setAppliedFilters({...appliedFilters, event_search: ""});
      updateEventsList({...appliedFilters, event_search: ""}, "handleSearch");
    }
  }

  const handleRemoveFilter = (filterName) => {
    setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([key, _]) => key !== filterName)));
  }

  const handleFilterDate = (date) => {
    setAppliedFilters({...appliedFilters, ...date});
  }

  const handlePage = (num) => {
    setAppliedFilters({...appliedFilters, page: num});
  }

  const timestampToDate = (timestamp) => {
    return new Intl.DateTimeFormat("ru-RU", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(timestamp * 1000);
  }

  const handleGoToCustomerCard = (id) => {
    window.open(`/customers/${id}`, "_blank");
  }

  const filtersToURLparams = (filters) => {
    let URLparams = "";
    for (let i = 0, keys = Object.keys(filters); i < keys.length; ++i) {
      if (Array.isArray(filters[keys[i]]))
        for (const arg of filters[keys[i]]) URLparams += `&${keys[i]}=${arg}`;
      else URLparams += `&${keys[i]}=${filters[keys[i]]}`;
    }
    return URLparams;
  }

  const handleNotification = (data) => {
    setNotification(data);
  }

  useEffect(() => {
    if (!statusesStore.loaded)
      dispatch(getEventsStatusesData(`/api/v1/frontend/event_statuses/list`));
    if (!filtersStore.loaded)
      dispatch(getEventsFiltersData(`/api/v1/frontend/events/filters`, {
        _user_token,
        application_id: userInfoStore.data.selected.application
      }));
  }, [])

  useEffect(() => {
    if (eventsStore.error || statusesStore.error) {
      const error = eventsStore.error || statusesStore.error;
      handleNotification({show: true, title: "Error", text: error, time: "Now"});
    }
  }, [eventsStore.error, statusesStore.error])

  useEffect(() => {
    if (eventsStore.loaded && statusesStore.loaded) setTableLoading(false);
    else setTableLoading(true);
  }, [eventsStore.loaded, statusesStore.loaded])

  useEffect(() => {
    const local = localStorage.getItem("eventLogAppliedFilters");
    const applied = JSON.stringify((appliedFilters));

    if (!initialized || local !== applied || selectedApp !== userInfoStore.data.selected.application) {
      setInitialized(true);
      setSelectedApp(userInfoStore.data.selected.application);
      if (Object.keys(appliedFilters).length > 0 && appliedFilters[`created_at[]`][0] && appliedFilters[`created_at[]`][1]) {
        updateEventsList(appliedFilters);
        localStorage.setItem("eventLogAppliedFilters", applied);
      }
    }
  }, [userInfoStore.data.selected.application, appliedFilters])

  return (
    <Container className="pt-4 px-2 px-lg-5">

      <Notification notification={notification} handleNotification={handleNotification}/>

      <Row className="d-flex align-items-baseline m-0">
        <Col xs={"auto"} className="ps-0 pe-3">
          <h2 className="SFProDisplay-Bold m-0">
            {appConfig.EventLog.wording.text_1}
          </h2>
        </Col>
      </Row>

      {filtersStore.loaded &&
        <Row className="d-flex justify-content-between align-items-center pt-2 m-0">
          <Col xs={12} lg="auto" className="px-0">
            <CustomDatepicker
              dateFilterKey="created_at[]"
              appliedDate={appliedFilters[`created_at[]`]}
              changeDate={handleFilterDate}
              dateKey="eventlog"
              endCorrection={1}
              showToday
            />
          </Col>

          <Col xs={12} lg="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
            <InputGroup className="border border-2 border-secondary rounded-5">
              <InputGroup.Text className="border-0">
                <img src={searchIcon} className="img-fluid" alt="search"/>
              </InputGroup.Text>
              <Form.Control
                defaultValue={appliedFilters.customer_search}
                type="text"
                placeholder={appConfig.EventLog.wording.text_4}
                className="border-0 ps-0 pe-5"
                onChange={handleSearch}
              />
            </InputGroup>
          </Col>

          <Col xs={12} lg="auto" className="px-0">
            <Row className="justify-content-between m-0">
              <Col xs="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
                <a
                  href={`${window.location.origin}/api/v1/frontend/events/export?${filtersToURLparams({
                    _user_token,
                    application_id: userInfoStore.data.selected.application, ...appliedFilters
                  })}`}
                  className="btn btn-secondary rounded-5"
                  download="export_events.csv"
                >
                  {appConfig.EventLog.wording.text_3}
                </a>
              </Col>

              <Col xs="auto" className="pt-3 pt-lg-0 px-0">
                <Filters
                  availableFilters={filtersStore.data}
                  appliedFilters={appliedFilters}
                  handleAppliedFilters={handleAppliedFilters}
                  applyFilters={() => updateEventsList(appliedFilters)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }

      {filtersStore.loaded &&
        < Row className="pt-3 m-0">
          <Col className="px-0">
            <AppliedFilters
              appliedFilters={appliedFilters}
              exceptFilters={["created_at[]", "event_search", "page", "test"]}
              availableFilters={filtersStore.data}
              handleRemoveFilter={handleRemoveFilter}
            />
          </Col>
        </Row>
      }

      {tableLoading ? <Loader/> :
        <Container fluid className="px-0">
          <Row className="pt-4 m-0">
            <Col className="px-0">
              <Table responsive>

                <thead>
                <tr className="white-space-nowrap">
                  {Object.keys(eventsStore.data.columns).map((col, i) => (
                    <th className={`opacity-50 fw-normal border-0`} key={i}>
                      {eventsStore.data.columns[col]}
                    </th>
                  ))}
                </tr>
                </thead>

                <tbody className="border-top-0">
                {eventsStore.data.records.length > 0
                  ?
                  eventsStore.data.records.map((record, i) => (
                    <tr className="white-space-nowrap" key={i}>
                      {Object.keys(eventsStore.data.columns).map((col, index) => {
                        if (col === "customer_identifier") return (
                          <td className="cursor-pointer custom-cell"
                              onClick={() => handleGoToCustomerCard(record.customer_id)} key={index}>
                            {record[col]}
                          </td>
                        );
                        else if (col === "status") return (
                          <td className="custom-cell" key={index}>
                            <Badge bg={statusType[record[col]]}
                                   className={`${statusColor[record[col]]} fw-normal py-2 px-3 rounded-10`}>
                              {statusesStore.data.find(x => x.id === record[col]).name}
                            </Badge>
                          </td>
                        );
                        else if (col === "created_at" || col === "updated_at") return (
                          <td className="custom-cell" key={index}>
                            {timestampToDate(record[col])}
                          </td>
                        );
                        else if (col === "description") return (
                          <td className="description-cell" key={index}>
                            {record[col]}
                          </td>
                        );
                        else return (
                            <td className={`custom-cell ${typeof record[col] === "number" ? "text-end" : ""}`}
                                key={index}>
                              {record[col]}
                            </td>
                          );
                      })}
                    </tr>
                  ))
                  :
                  <tr>
                    <td className="text-center custom-cell border-none opacity-50 pt-5"
                        colSpan={Object.keys(eventsStore.data.columns).length}>
                      {appConfig.EventLog.wording.text_2}
                    </td>
                  </tr>
                }
                </tbody>

              </Table>
            </Col>
          </Row>

          <Row className="m-0">
            <Col className="px-0">
              <CustomPagination
                totalPages={eventsStore.data.total_pages}
                currentPage={eventsStore.data.current_page}
                handlePage={handlePage}
              />
            </Col>
          </Row>
        </Container>
      }

    </Container>
  )
}