import {
	OVERVIEW_DATA,
	OVERVIEW_LOADED,
	OVERVIEW_ERROR,
	OVERVIEW_FILTERS_DATA,
	OVERVIEW_FILTERS_LOADED,
	OVERVIEW_FILTERS_ERROR,
} from "../reducers/overviewReducer";

export const getOverviewData = (url, params) => dispatch => {

	dispatch({ type: OVERVIEW_LOADED, payload: false });

	const filtersToURLparams = (filters) => {
		let URLparams = "";
		for (let i = 0, keys = Object.keys(filters); i < keys.length; ++i) {
			if (Array.isArray(filters[keys[i]]))
				for (const arg of filters[keys[i]]) URLparams += `&${keys[i]}=${arg}`;
			else URLparams += `&${keys[i]}=${filters[keys[i]]}`;
		}
		return URLparams;
	}

	fetch(`${url}?${filtersToURLparams(params)}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: OVERVIEW_DATA, payload: data._data.metrics });
				dispatch({ type: OVERVIEW_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: OVERVIEW_DATA, payload: [] });
			dispatch({ type: OVERVIEW_LOADED, payload: true });
			dispatch({ type: OVERVIEW_ERROR, payload: err.message });
		});
}

export const getOverviewFiltersData = (url, params) => (dispatch) => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: OVERVIEW_FILTERS_DATA, payload: data._data.filters });
				dispatch({ type: OVERVIEW_FILTERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: OVERVIEW_FILTERS_ERROR, payload: err.message });
		});
}