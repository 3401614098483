import { Container, Row, Col, Accordion } from "react-bootstrap";

export default function CustomerCardSidebar(props) {

	return (
		<Accordion defaultActiveKey={["details"]} alwaysOpen>
			<Accordion.Item
				className="border-0"
				eventKey="details"
			>
				<Accordion.Button className="custom-accordion border-bottom border-1 border-secondary">
					<h5 className="SFProDisplay-Bold ps-2 m-0">
						{props.config.wording.text_1}
					</h5>
				</Accordion.Button>
				<Accordion.Body className="py-3 px-0">
					<Container fluid className="px-0">
						<Row className="m-0">
							{Object.keys(props.details.rows).map(key =>
								<Col xs={4} lg={12} className="py-2 px-0" key={key}>
									<p className="SFProDisplay-Semibold m-0">
										{props.details.rows[key]}
									</p>
									<p className="text-truncate opacity-50 m-0">
										{props.details.values[key]
											? key === "platform"
												? props.platforms.find(x => x.id === props.details.values.platform).name
												: props.details.values[key]
											: props.config.wording.text_2
										}
									</p>
								</Col>
							)}
						</Row>
					</Container>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}