import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./styles/Theme.scss";
import "./styles/App.scss";

import AppRouter from "./components/AppRouter";
import { ConfigContext } from "./context";
import { AppContext } from "./context";
import { store } from "./store";

export default function App() {
	const selectedOrg = localStorage.getItem("selectedOrg");
	const selectedApp = localStorage.getItem(`org-${selectedOrg}SelectedApp`);
	const [isAuth, setIsAuth] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [allAppsOverviewEnabled, setAllAppsOverviewEnabled] = useState(selectedApp !== null && +selectedApp === 0);
	const appConfig = document._CNFG_;

	useEffect(() => {
		if (localStorage.getItem("userToken")) setIsAuth(true);
		setLoading(false);
	}, [])

	return (
		<Provider store={store}>
			<ConfigContext.Provider value={{ appConfig }}>
				<AppContext.Provider value={{
					isAuth,
					setIsAuth,
					isLoading,
					setLoading,
					allAppsOverviewEnabled,
					setAllAppsOverviewEnabled
				}}>
					<BrowserRouter>
						<AppRouter />
					</BrowserRouter>
				</AppContext.Provider>
			</ConfigContext.Provider>
		</Provider>
	);
}