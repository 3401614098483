import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import CustomDropdown from "./UI/CustomDropdown";
import CustomModal from "./CustomModal";
import dots from "../assets/images/dots.svg";

export default function SettingsAppleSearchAdsApi(props) {

	const initialState = {
		file: {
			id: "",
			name: ""
		},
		file_id: "",
		currency: "",
		company_identifier: "",
		client_identifier: "",
		team_identifier: "",
		key_identifier: ""
	}

	const [settingsData, setSettingsData] = useState(initialState);
	const _user_token = localStorage.getItem("userToken");
	const [showModal, setShowModal] = useState(false);
	const [isInputDisabled, setInputDisabled] = useState(false)
	const [isSaveDisabled, setIsSaveDisabled] = useState(true)

	const handleModal = () => {
		setShowModal(!showModal);
	}

	const handleModalContent = (id) => {
		if (id) {
			setSettingsData(...props.settingsData.filter(obj => obj.id === id));
			setIsSaveDisabled(false);
		} else {
			setSettingsData(initialState);
			setIsSaveDisabled(true);
		}
		setShowModal(true);
	}

	const uploadFile = async (e) => {
		const formData = new FormData();
		formData.set("file", e.target.files[0]);
		formData.set("_user_token", _user_token);
		formData.set("public", 1);

		setSettingsData({ ...settingsData, file: { ...settingsData.file, name: "Loading..." } });
		setInputDisabled(true);
		setIsSaveDisabled(true);

		try {
			const response = await (await
				fetch(`/api/v1/frontend/files/create`,
					{
						method: "POST",
						body: formData
					}
				)).json();
			if (response._code === 200) {
				handleSettingsData("file_id", response._data.file_id);
				handleSettingsData("file", { id: response._data.file_id, name: e.target.files[0].name });
				setInputDisabled(false);
				setIsSaveDisabled(false);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			props.handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
			setSettingsData({ ...settingsData, file: { ...settingsData.file, name: settingsData.file.name } });
			setInputDisabled(false);
			setIsSaveDisabled(!settingsData.file.name);
		}
	}

	const handleSettingsData = (key, value) => {
		setSettingsData({ ...settingsData, [key]: value });
	}

	return (
		<Container fluid className="px-0">
			<Row className="justify-content-between m-0">
				<Col xs={12} lg={6} className="px-0">
					<p className="SFProDisplay-Medium mb-2">
						{props.config.wording.text_1}
					</p>
					<p className="text-dark mb-2">
						{props.config.wording.text_2}
					</p>
				</Col>
				<Col xs={12} lg="auto" className="d-flex align-items-center px-0">
					<Button className="px-4 rounded-5" onClick={() => handleModalContent()}>
						{props.config.wording.text_4}
					</Button>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col className="px-0">
					<Container fluid className="px-0">

						<Row className="p-2 m-0">
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_5}
							</Col>
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_6}
							</Col>
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_7}
							</Col>
							<Col className="opacity-50 text-start px-0">
								{props.config.wording.text_8}
							</Col>
						</Row>

						{props.settingsData && props.settingsData.map((settings, i) =>
							<Row className="d-flex align-items-center border-top border-1 border-secondary p-2 m-0" key={i}>
								<Col className="px-0">
									{settings.id}
								</Col>

								<Col className="px-0">
									{settings.company_identifier}
								</Col>

								<Col className="px-0">
									{settings.currency}
								</Col>

								<Col className="px-0">
									<CustomDropdown noArrow={true} buttonText={<img src={dots} className="img-fluid" alt="dots" />}>
										<Row className="pt-2 pb-1 m-0">
											<Col>
												<Button variant="link" className="text-decoration-none text-start text-black w-100" onClick={() => handleModalContent(settings.id)}>
													{props.config.wording.text_9}
												</Button>
											</Col>
										</Row>
										<Row className="pt-1 pb-2 m-0">
											<Col>
												<Button variant="link" className="text-decoration-none text-start text-dark-red w-100" onClick={() => props.handleModal("delete_search_ads_details", settings.id)}>
													{props.config.wording.text_10}
												</Button>
											</Col>
										</Row>
									</CustomDropdown>
								</Col>
							</Row>
						)}

					</Container>
				</Col>
			</Row>

			<CustomModal
				handleModal={handleModal}
				show={showModal}
				fullscreen={false}
			>
				<Container fluid className="p-3">
					<Row className="pt-4 m-0">
						<Col xs={"auto"} className="ps-0 pe-2">
							<p className="SFProDisplay-Medium mb-2">
								{props.config.wording.text_11}
							</p>
							<p className="text-dark m-0">
								{props.config.wording.text_12}
							</p>
						</Col>
					</Row>
					<Row className="d-flex align-items-center pt-2 m-0">
						<Col xs={"auto"} className="ps-0 pe-2">
							<Form.Group>
								<Form.Label className="btn btn-primary rounded-5 px-3 m-0">
									{props.config.wording.text_13}
									<Form.Control
										name="file"
										type="file"
										className="d-none"
										onChange={uploadFile}
										onClick={(e) => e.target.value = null}
										disabled={isInputDisabled}
									/>
								</Form.Label>
							</Form.Group>
						</Col>
						<Col xs={"auto"} className="ps-0 pe-2">
							<p className="opacity-50 m-0">
								{settingsData?.file?.name ? settingsData.file.name : settingsData.file_id}
							</p>
						</Col>
					</Row>

					<Row className="py-4 m-0">
						<Col className="px-0">
							<Row className="pt-4 m-0">
								<Form.Group as={Col} className="ps-0 pe-2">
									<Form.Label className="SFProDisplay-Semibold">
										{props.config.wording.text_16}
									</Form.Label>
									<Form.Control
										type="text"
										value={settingsData.currency}
										onChange={(e) => handleSettingsData("currency", e.target.value)}
										placeholder={props.config.wording.text_17}
										disabled={isInputDisabled}
									/>
								</Form.Group>
								<Form.Group as={Col} className="ps-2 pe-0">
									<Form.Label className="SFProDisplay-Semibold">
										{props.config.wording.text_18}
									</Form.Label>
									<Form.Control
										type="text"
										value={settingsData.company_identifier}
										onChange={(e) => handleSettingsData("company_identifier", e.target.value)}
										placeholder={props.config.wording.text_19}
										disabled={isInputDisabled}
									/>
								</Form.Group>
							</Row>
							<Form.Group className="pt-4">
								<Form.Label className="SFProDisplay-Semibold">
									{props.config.wording.text_20}
								</Form.Label>
								<Form.Control
									type="text"
									value={settingsData.client_identifier}
									onChange={(e) => handleSettingsData("client_identifier", e.target.value)}
									placeholder={props.config.wording.text_21}
									disabled={isInputDisabled}
								/>
							</Form.Group>
							<Form.Group className="pt-4">
								<Form.Label className="SFProDisplay-Semibold">
									{props.config.wording.text_22}
								</Form.Label>
								<Form.Control
									type="text"
									value={settingsData.team_identifier}
									onChange={(e) => handleSettingsData("team_identifier", e.target.value)}
									placeholder={props.config.wording.text_23}
									disabled={isInputDisabled}
								/>
							</Form.Group>
							<Form.Group className="pt-4">
								<Form.Label className="SFProDisplay-Semibold">
									{props.config.wording.text_24}
								</Form.Label>
								<Form.Control
									type="text"
									value={settingsData.key_identifier}
									onChange={(e) => handleSettingsData("key_identifier", e.target.value)}
									placeholder={props.config.wording.text_25}
									disabled={isInputDisabled}
								/>
							</Form.Group>
						</Col>
					</Row>

					<Row className="d-flex align-items-center border-top border-secondary pt-4 m-0">
						<Col xs="auto" className="px-0">
							<Button className="px-4 rounded-5" onClick={() => props.updateSettings("edit_search_ads_details", settingsData, handleModal)} disabled={isSaveDisabled}>
								{settingsData.id ? props.config.wording.text_26 : props.config.wording.text_27}
							</Button>
						</Col>
						<Col xs="auto" className="px-4">
							<Button variant="link" className="text-decoration-none text-primary p-0" onClick={() => setShowModal(false)} disabled={isInputDisabled}>
								{props.config.wording.text_28}
							</Button>
						</Col>
					</Row>
				</Container>
			</CustomModal>

		</Container>
	);
}