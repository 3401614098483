import { Container, Row, Col, Form, Button } from "react-bootstrap";

export default function IntegrationsFacebook(props) {

	return (
		<Container className="pt-4 pb-5 px-3 px-lg-5">

			<Row className="d-flex align-items-center m-0">
				<Col className="px-0">
					<h2 className="SFProDisplay-Bold m-0">
						<span className="text-capitalize">{props.config.wording.text_5}</span> {props.config.wording.text_6}
					</h2>
				</Col>
			</Row>

			<Row className="d-flex align-items-center pt-4 m-0">
				<Col className="px-0">
					<h5 className="SFProDisplay-Bold m-0">
						{props.config.wording.text_1}
					</h5>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col lg={4} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_2}
						</Form.Label>
						<Form.Control
							type="text"
							placeholder={props.config.wording.text_3}
							defaultValue={props.integtationData.pixel_identifier}
							key={props.integtationData.pixel_identifier}
							className="mb-2"
							onChange={(e) => props.handleIntegrationsSettings("pixel_identifier", e.target.value)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={props.handleSubmitIntegrationSettings}>
						{props.config.wording.text_4}
					</Button>
				</Col>
			</Row>

		</Container>
	)
}