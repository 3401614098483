import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { ConfigContext } from "../context";
import { setUserInfoData, getUserInfoData } from "../actions/userInfoActions";
import { getAppSettingsData } from "../actions/appSettingsAction";
import SettingsIosSdk from "../components/SettingsIosSdk";
import SettingsAndroidSdk from "../components/SettingsAndroidSdk";
import SettingsAppStoreApi from "../components/SettingsAppStoreApi";
import SettingsAppleSearchAdsApi from "../components/SettingsAppleSearchAdsApi";
import SettingsStripe from "../components/SettingsStripe";
import SettingsPaypal from "../components/SettingsPaypal";
import Loader from "../components/UI/Loader";
import Notification from "../components/UI/Notification";
import CustomModal from "../components/CustomModal";

export default function Settings() {

	const { appConfig } = useContext(ConfigContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const appSettingsStore = useSelector(state => state.appSettings);
	const [appName, setAppName] = useState("");
	const [backendURL, setBackendURL] = useState("");
	const [iconFileUrl, setIconFileUrl] = useState("");
	const [iconFileId, setIconFileId] = useState("");
	const [settingsData, setSettingsData] = useState(null);
	const [settingsLoading, setSettingsLoading] = useState(true);
	const [deletePath, setDeletePath] = useState(null);
	const [deleteID, setDeleteID] = useState(null);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const [showModal, setShowModal] = useState(false);
	const _user_token = localStorage.getItem("userToken");
	const activeTab = new URL(document.location).searchParams.get("activeTab") || "ios_sdk";
	const [sbpEnabled, setsbpEnabled] = useState(false);
	const [sbpStart, setSbpStart] = useState(null);
	const [sbpStop, setSbpStop] = useState(null);
	const [isSaveDisabled, setIsSaveDisabled] = useState(false)

	const handleModal = (path, id) => {
		setShowModal(true);
		setDeletePath(path);
		setDeleteID(id)
	}

	const loadSettings = () => {
		dispatch(getAppSettingsData(`/api/v1/frontend/applications/show`, { _user_token, application_id: userInfoStore.data.selected.application }));
	}

	const updateSettings = async (path, data, callback) => {
		if (!data.file_id && data.file) data.file_id = data.file.id;
		try {
			const response = await (await
				fetch(`/api/v1/frontend/applications/${path}`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							application_id: userInfoStore.data.selected.application,
							...data
						})
					}
				)).json();
			if (response._code === 200) {
				if (callback) callback();
				loadSettings();
				handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
				dispatch(getUserInfoData(`/api/v1/frontend/me/info`, { _user_token }));
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const deleteSettings = async () => {
		try {
			const response = await (await
				fetch(`/api/v1/frontend/applications/${deletePath}`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ _user_token, application_id: userInfoStore.data.selected.application, id: deleteID })
					}
				)).json();
			if (response._code === 200) {
				setShowModal(false);

				if (deleteID) {
					handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
					setDeleteID(null);
				} else {
					handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
				}

				if (deletePath === "delete") {
					dispatch(setUserInfoData({
						selected: {
							organization: userInfoStore.data.selected.organization,
							application: 0
						}
					}));
					dispatch(getUserInfoData(`/api/v1/frontend/me/info`, { _user_token }));
					history.push(`/`);
				} else {
					loadSettings();
				}
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const uploadFile = async (e) => {
		const formData = new FormData();
		formData.set("file", e.target.files[0]);
		formData.set("_user_token", _user_token);
		formData.set("public", 1);

		setIconFileUrl("Loading...");
		setIsSaveDisabled(true);

		try {
			const response = await (await
				fetch(`/api/v1/frontend/files/create`, {
					method: "POST",
					body: formData
				})).json();
			if (response._code === 200) {
				setIconFileId(response._data.file_id);
				setIconFileUrl(e.target.files[0].name);
				setIsSaveDisabled(false);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
			setIsSaveDisabled(false);
			setIconFileUrl(`${iconFileUrl}`.split("/").pop());
		}
	}

	const formatDate = (timestamp) => {
		const year = new Date(timestamp).getFullYear();
		const month = new Date(timestamp).getMonth() + 1;
		const date = new Date(timestamp).getDate();
		return `${year}-${month < 10 ? 0 : ""}${month}-${date < 10 ? 0 : ""}${date}`;
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	const handleSbpEnable = (status) => {
		if (status) {
			setSbpStart(new Date());
		} else {
			setSbpStart(null);
			setSbpStop(null);
		}
		setsbpEnabled(status);
	}

	useEffect(() => {
		if (appSettingsStore.loaded) {
			setAppName(appSettingsStore.data.name);
			setBackendURL(appSettingsStore.data.product_backend_url);
			setIconFileUrl(appSettingsStore.data.icon_url);
			setIconFileId(appSettingsStore.data.icon_file_id);
			setSettingsData(appSettingsStore.data.integrations);

			if (appSettingsStore.data.sbp_start) {
				setSbpStart(new Date(appSettingsStore.data.sbp_start));
				setsbpEnabled(true);
			} else {
				setSbpStart(null);
				setsbpEnabled(false);
			}

			if (appSettingsStore.data.sbp_stop) {
				setSbpStop(new Date(appSettingsStore.data.sbp_stop));
			} else {
				setSbpStop(null);
			}

			setSettingsLoading(false);
		}
	}, [appSettingsStore.loaded, appSettingsStore])

	useEffect(() => {
		if (appSettingsStore.error)
			handleNotification({ show: true, title: "Error", text: appSettingsStore.error, time: "Now" });
	}, [appSettingsStore.error])

	useEffect(() => {
		if (appSettingsStore.loaded) {
			loadSettings();
			setSettingsLoading(true);
		} else {
			loadSettings();
		}
	}, [userInfoStore.data.selected.application])

	return (
		settingsLoading ? <Loader /> :
			<Container className="pt-4 px-2 px-lg-5">

				<Notification notification={notification} handleNotification={handleNotification} />

				<CustomModal
					show={showModal}
					closeButton={false}
					fullscreen={false}
					footer={
						<Container fluid className="py-2 px-0">
							<Row className="d-flex justify-content-end m-0">
								<Col xs="auto" className="px-2">
									<Button variant="secondary" className="rounded-5" onClick={() => setShowModal(false)}>
										{appConfig.Settings.wording.text_23}
									</Button>
								</Col>
								<Col xs="auto" className="px-2">
									<Button variant="dark-red" className="rounded-5" onClick={deleteSettings}>
										{appConfig.Settings.wording.text_24}
									</Button>
								</Col>
							</Row>
						</Container>
					}
				>
					<Container fluid className="p-3">
						<Row className="m-0">
							<Col className="px-0">
								<h3>
									{deletePath === "delete" ? appConfig.Settings.wording.text_13 : appConfig.Settings.wording.text_12}
								</h3>
								<p>
									{appConfig.Settings.wording.text_14}
									{deletePath === "delete"
										? appConfig.Settings.wording.text_15
										: deletePath === "delete_ios_sdk"
											? appConfig.Settings.wording.text_16
											: deletePath === "delete_appstore_api_details"
												? appConfig.Settings.wording.text_17
												: deletePath === "delete_search_ads_details"
													? appConfig.Settings.wording.text_18
													: deletePath === "delete_google_details"
														? appConfig.Settings.wording.text_19
														: deletePath === "delete_stripe_settings"
															? appConfig.Settings.wording.text_20
															: deletePath === "delete_paypal_settings"
																? appConfig.Settings.wording.text_21
																: ""}
									{appConfig.Settings.wording.text_22}
								</p>
							</Col>
						</Row>
					</Container>
				</CustomModal>

				<Row className="m-0">
					<Col className="px-0">
						<h2 className="SFProDisplay-Bold m-0">
							{appConfig.Settings.wording.text_1}
						</h2>
					</Col>
				</Row>

				<Tabs defaultActiveKey={activeTab} className="pt-4">
					<Tab eventKey="general" title={appConfig.Settings.wording.text_30} className="py-4">
						<Row className="m-0">
							<Col lg={5} className="px-0">
								<Form.Group>
									<Form.Label className="SFProDisplay-Semibold">
										{appConfig.Settings.wording.text_2}
									</Form.Label>
									<Form.Control
										type="text"
										value={appName || ""}
										onChange={(e) => setAppName(e.target.value)}
										placeholder="Enter your app name"
									/>
									<Form.Text muted>
										{appConfig.Settings.wording.text_11}
									</Form.Text>
								</Form.Group>
								<Form.Group>
									<Form.Label className="SFProDisplay-Semibold pt-4">
										{appConfig.Settings.wording.text_3}
									</Form.Label>
									<Form.Control
										type="text"
										value={backendURL || ""}
										onChange={(e) => setBackendURL(e.target.value)}
										placeholder="Enter product backend URL"
									/>
									<Form.Text muted>
										{appConfig.Settings.wording.text_10}
									</Form.Text>
								</Form.Group>
								<Form.Group>
									<Form.Label className="SFProDisplay-Semibold pt-4">
										{appConfig.Settings.wording.text_4}
									</Form.Label>
									<Form.Control
										as="textarea"
										value={appSettingsStore.data.api_key || ""}
										readOnly
									/>
									<Form.Text muted>
										{appConfig.Settings.wording.text_5}
									</Form.Text>
								</Form.Group>

								<Row className="pt-3 m-0">
									<Col xs={"auto"} className="ps-0 pe-2">
										<p className="SFProDisplay-Semibold m-0">
											{appConfig.Settings.wording.text_8}
										</p>
									</Col>
								</Row>

								<Row className="d-flex align-items-center pt-2 m-0">
									<Col xs={"auto"} className="ps-0 pe-2">
										<Form.Group>
											<Form.Label className="btn btn-primary rounded-5 px-3 m-0">
												{appConfig.Settings.wording.text_9}
												<Form.Control
													name="file"
													type="file"
													className="d-none"
													onChange={uploadFile}
													onClick={(e) => e.target.value = null}
												/>
											</Form.Label>
										</Form.Group>
									</Col>
									<Col xs={"auto"} className="ps-0 pe-2">
										<p className="opacity-50 m-0">
											{`${iconFileUrl}`.split("/").pop()}
										</p>
									</Col>
								</Row>

								<Form.Group className="pt-4">
									<Form.Check
										className="SFProDisplay-Semibold"
										label={appConfig.Settings.wording.text_25}
										checked={sbpEnabled}
										onChange={(e) => handleSbpEnable(e.target.checked)}
									/>
								</Form.Group>

								<Row className="pt-1 pb-4 m-0">
									<Col className="px-0">
										<Form.Group>
											<DatePicker
												className="form-control"
												dateFormat="yyyy-MM-dd"
												selected={sbpStart}
												disabled={!sbpEnabled}
												onChange={(date) => setSbpStart(date)}
												placeholderText={appConfig.Settings.wording.text_26}
											/>
											<Form.Text muted>
												{appConfig.Settings.wording.text_27}
											</Form.Text>
										</Form.Group>
									</Col>

									<Col xs="auto" className="pt-2 px-2">-</Col>

									<Col className="px-0">
										<Form.Group>
											<DatePicker
												className="form-control"
												dateFormat="yyyy-MM-dd"
												selected={sbpStop}
												disabled={!sbpEnabled}
												onChange={(date) => setSbpStop(date)}
												placeholderText={appConfig.Settings.wording.text_28}
											/>
											<Form.Text muted>
												{appConfig.Settings.wording.text_29}
											</Form.Text>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="d-flex align-items-center justify-content-between border-top border-secondary pt-4 m-0">
							<Col xs="auto" className="px-0">
								<Button
									className="px-4 rounded-5"
									onClick={() => updateSettings("edit", {
										name: appName,
										product_backend_url: backendURL,
										icon_file_id: iconFileId,
										sbp_start: sbpStart ? formatDate(sbpStart) : null,
										sbp_stop: sbpStop ? formatDate(sbpStop) : null,
									})}
									disabled={isSaveDisabled}
								>
									{appConfig.Settings.wording.text_6}
								</Button>
							</Col>
							<Col xs="auto" className="px-4">
								<Button variant="link" className="text-decoration-none text-dark-red p-0" onClick={() => handleModal("delete")}>
									{appConfig.Settings.wording.text_7}
								</Button>
							</Col>
						</Row>
					</Tab>

					<Tab eventKey="ios_sdk" title={appConfig.Settings.wording.text_31} className="py-4">
						<SettingsIosSdk
							settingsData={settingsData.ios_sdk}
							updateSettings={updateSettings}
							handleModal={handleModal}
							config={appConfig.Settings.iosSdk}
							handleNotification={handleNotification}
						/>
					</Tab>

					<Tab eventKey="appstore_keys" title={appConfig.Settings.wording.text_32} className="py-4">
						<SettingsAppStoreApi
							settingsData={settingsData.appstore_keys}
							updateSettings={updateSettings}
							handleModal={handleModal}
							config={appConfig.Settings.appStoreApi}
							handleNotification={handleNotification}
						/>
					</Tab>

					<Tab eventKey="search_ads_keys" title={appConfig.Settings.wording.text_33} className="py-4">
						<SettingsAppleSearchAdsApi
							settingsData={settingsData.search_ads_keys}
							updateSettings={updateSettings}
							handleModal={handleModal}
							config={appConfig.Settings.appleSearchAdsApi}
							handleNotification={handleNotification}
						/>
					</Tab>

					<Tab eventKey="android_sdk" title={appConfig.Settings.wording.text_34} className="py-4">
						<SettingsAndroidSdk
							settingsData={settingsData.android_sdk}
							updateSettings={updateSettings}
							handleModal={handleModal}
							config={appConfig.Settings.androidSdk}
							handleNotification={handleNotification}
						/>
					</Tab>

					<Tab eventKey="stripe_settings" title={appConfig.Settings.wording.text_35} className="py-4">
						<SettingsStripe
							settingsData={settingsData.stripe_settings}
							updateSettings={updateSettings}
							handleModal={handleModal}
							config={appConfig.Settings.stripe}
							handleNotification={handleNotification}
						/>
					</Tab>

					<Tab eventKey="paypal_settings" title={appConfig.Settings.wording.text_36} className="py-4">
						<SettingsPaypal
							settingsData={settingsData.paypal_settings}
							updateSettings={updateSettings}
							handleModal={handleModal}
							config={appConfig.Settings.paypal}
							handleNotification={handleNotification}
						/>
					</Tab>
				</Tabs>

			</Container>
	);
}