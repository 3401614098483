import Login from "../pages/Login";

import Overview from "../pages/Overview";
import Installs from "../pages/Installs";
import Customers from "../pages/Customers";
import CustomerCard from "../pages/CustomerCard";
import Integrations from "../pages/Integrations";
import IntegrationSettings from "../pages/IntegrationSettings";
import Reports from "../pages/Reports";
import EventLog from "../pages/EventLog";
import Settings from "../pages/Settings";
import Account from "../pages/Account";
import Organizations from "../pages/Organizations";
import TeamMembers from "../pages/TeamMembers";

export const publicRoutes = [
	{ path: "/login", component: Login, exact: true }
];

export const allAppsRoutes = [
	{ path: "/overview", component: Overview, exact: true },
	{ path: "/reports", component: Reports, exact: true },
	{ path: "/events", component: EventLog, exact: true },
	{ path: "/account", component: Account, exact: true },
	{ path: "/organizations", component: Organizations, exact: true },
	{ path: "/team", component: TeamMembers, exact: true },
];

export const privateRoutes = [
	{ path: "/overview", component: Overview, exact: true },
	{ path: "/installs", component: Installs, exact: true },
	{ path: "/customers", component: Customers, exact: true },
	{ path: "/customers/:id", component: CustomerCard, exact: true },
	{ path: "/integrations", component: Integrations, exact: true },
	{ path: "/integrations/:id", component: IntegrationSettings, exact: true },
	{ path: "/reports", component: Reports, exact: true },
	{ path: "/events", component: EventLog, exact: true },
	{ path: "/settings/", component: Settings, exact: true },
	{ path: "/account", component: Account, exact: true },
	{ path: "/organizations", component: Organizations, exact: true },
	{ path: "/team", component: TeamMembers, exact: true },
];