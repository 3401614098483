import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { ConfigContext } from "../context";
import AppsList from "./AppsList";
import UserActions from "./UserActions";

export default function Header() {

	const { appConfig } = useContext(ConfigContext);

	return (
		<Container fluid className="bg-light px-0">
			<Container className="px-2 px-lg-5">
				<Row className="m-0">
					<Col className="px-0">
						<AppsList config={appConfig.AppsList} />
					</Col>
					<Col className="text-end px-0">
						<UserActions config={appConfig.UserMenu} />
					</Col>
				</Row>
			</Container>
		</Container>
	);
}