import {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";

import {getPlatformsData} from "../actions/platformsActions";
import {ConfigContext} from "../context";
import ReportCohorts from "../components/ReportCohorts";
import ReportAsa from "../components/ReportAsa";
import Notification from "../components/UI/Notification";

export default function Reports() {

  const dispatch = useDispatch();
  const {appConfig} = useContext(ConfigContext);
  const platformsStore = useSelector(state => state.platforms);
  const [notification, setNotification] = useState({show: false, title: "", text: "", time: ""});

  const handleNotification = (data) => {
    setNotification(data);
  }

  useEffect(() => {
    if (!platformsStore.loaded) dispatch(getPlatformsData(`/api/v1/frontend/platforms/list`));
    if (platformsStore.error)
      handleNotification({show: true, title: "Error", text: platformsStore.error, time: "Now"});
  }, [platformsStore.loaded, platformsStore.error])

  return (
    <Container className="px-2 px-lg-5">

      <Notification notification={notification} handleNotification={handleNotification}/>

      <Row className="d-flex align-items-center justify-content-between pt-4 m-0">
        <Col xs={6} lg="auto" className="px-0 ps-lg-0 pe-lg-3">
          <Row className="m-0 d-flex align-items-baseline">
            <Col xs={12} lg="auto" className="ps-0 pe-3">
              <h2 className="SFProDisplay-Bold m-0">
                {appConfig.Reports.wording.text_1}
              </h2>
            </Col>
          </Row>
        </Col>
      </Row>

      {platformsStore.loaded &&
        <Tabs defaultActiveKey="Cohorts" className="pt-4">
          <Tab eventKey="Cohorts" title={appConfig.Reports.wording.text_2} className="py-4" mountOnEnter>
            <ReportCohorts config={appConfig.Reports.cohorts}/>
          </Tab>
          <Tab eventKey="Apple" title={appConfig.Reports.wording.text_3} className="py-4" mountOnEnter>
            <ReportAsa config={appConfig.Reports.appleSA}/>
          </Tab>
        </Tabs>
      }

    </Container>
  );
}