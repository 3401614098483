import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Form } from "react-bootstrap";

import { getCohortsData, getCohortsFilters } from "../actions/reportsActions";
import CustomDatepicker from "../components/UI/CustomDatepicker";
import CustomDropdown from "../components/UI/CustomDropdown";
import Loader from "../components/UI/Loader";
import Filters from "../components/Filters";
import AppliedFilters from "../components/AppliedFilters";

const newUTCDate = () => {
	const date = new Date();
	return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
		date.getUTCDate(), date.getUTCHours(),
		date.getUTCMinutes(), date.getUTCSeconds());
}

const initialDateFilter = () => {
	let start = new Date(newUTCDate());
	start.setDate(start.getDate() - 30);
	let end = new Date();
	return { [`day[]`]: [Math.floor(start.getTime() / 1000), Math.floor(end.getTime() / 1000)] };
}

export default function ReportCohorts(props) {

	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const filtersStore = useSelector(state => state.cohortsFilters);
	const reportsStore = useSelector(state => state.cohorts);
	const savedFilters = JSON.parse(localStorage.getItem("cohortsAppliedFilters")) || initialDateFilter();
	const [columnsToHide, setColumnsToHide] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState(savedFilters);
	const _user_token = localStorage.getItem("userToken");
	const [initialized, setInitialized] = useState(false);
	const [selectedApp, setSelectedApp] = useState(null);

	const updateReportsList = (params = {}) => {
		dispatch(getCohortsData(`/api/v1/frontend/reports/cohorts/list`, { _user_token, application_id: userInfoStore.data.selected.application, ...params }));
	}

	const handleColumns = (colName, hide) => {
		if (hide) setColumnsToHide(columnsToHide.filter(x => x !== colName));
		else setColumnsToHide([...columnsToHide, colName]);
	}

	const filtersToURLparams = (filters) => {
		let URLparams = "";
		for (let i = 0, keys = Object.keys(filters); i < keys.length; ++i) {
			if (Array.isArray(filters[keys[i]]))
				for (const arg of filters[keys[i]]) URLparams += `&${keys[i]}=${arg}`;
			else URLparams += `&${keys[i]}=${filters[keys[i]]}`;
		}
		return URLparams;
	}

	const handleAppliedFilters = (type, key, value) => {
		if (type === "list" || type === "assoc list") {

			if (key in appliedFilters) {
				if (appliedFilters[key].includes(value)) {
					if (appliedFilters[key].length > 1) {
						setAppliedFilters({ ...appliedFilters, [key]: appliedFilters[key].filter(x => x !== value) });
					} else {
						setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
					}
				} else {
					setAppliedFilters({ ...appliedFilters, [key]: [...appliedFilters[key], value] });
				}
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: [value] });
			}

		} else if (type === "boolean" || type === "num_range") {

			if (!value) {
				setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: value });
			}

		}
	}

	const handleRemoveFilter = (filterName) => {
		setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([key, _]) => key !== filterName)));
	}

	const handleFilterDate = (date) => {
		setAppliedFilters({ ...appliedFilters, ...date });
	}

	const formatNum = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	const removeComma = (num) => {
		return num.toString().replace(/,/g, "");
	}

	const generateTable = () => {

		const head = [];
		const body = [];
		const lastRow = [];
		const colorMin = reportsStore.data.color_limits.min;
		const colorMax = reportsStore.data.color_limits.max;
		let total = {};

		for (const col in reportsStore.data.columns) {
			if (!columnsToHide.includes(col))
				head.push(
					<th className="opacity-50 fw-normal border-0 custom-cell" key={col}>
						{reportsStore.data.columns[col].name}
					</th>
				)
		}

		for (let i = 0; i < reportsStore.data.records.length; i++) {
			body.push(
				<tr className="custom-cell" key={i}>
					{Object.keys(reportsStore.data.columns).map((col, j) => {
						let value = Number(removeComma(reportsStore.data.records[i][col]));

						if (reportsStore.data.columns[col].total_action) {
							if (col in total) total[col].push(value)
							else total[col] = [value]
						}
						if (reportsStore.data.columns[col].colored) {
							let color = '255, 255, 255';
							let percent = 0;

							if (value > 0) {
								color = props.config.green;
								percent = value < colorMax ? (value * 100) / colorMax : 100
							} else if (value < 0) {
								color = props.config.red;
								percent = value > colorMin ? (value * 100) / colorMin : 100
							}

							if (!columnsToHide.includes(col)) return (
								<td
									className="custom-cell"
									key={j}
									style={{ backgroundColor: `rgba(${color}, ${percent}%)` }}
								>
									{value}
								</td>
							)
						} else {
							if (!columnsToHide.includes(col)) return (
								<td
									className="custom-cell"
									key={j}
								>
									{reportsStore.data.records[i][col]}
								</td>
							)
						}
					}
					)}
				</tr>
			)
		}

		for (const col in reportsStore.data.columns) {
			if (!columnsToHide.includes(col)) {
				let value = 0;
				if (col in total) {
					for (const num of total[col]) {
						value += Number(num);
					}
				}

				if (reportsStore.data.columns[col].total_action === 'sum') {
					lastRow.push(
						<td className="custom-cell bg-secondary" key={col}>
							{formatNum(value % 1 !== 0 ? value.toFixed(2) : value)}
						</td>
					)
				} else if (reportsStore.data.columns[col].total_action === 'avg') {
					value = value / total[col].length;
					lastRow.push(
						<td className="custom-cell bg-secondary" key={col}>
							{formatNum(value % 1 !== 0 ? value.toFixed(2) : value)}
						</td>
					)
				} else if (col !== "day") {
					lastRow.push(
						<td className="custom-cell bg-secondary" key={col} />
					)
				}
			}
		}

		return (
			<Table responsive>
				<thead>
					<tr>
						{head}
					</tr>
				</thead>
				<tbody className="border-top-0">
					{body}
					<tr>
						<td className="custom-cell bg-secondary">
							Total
						</td>
						{lastRow}
					</tr>
				</tbody>
			</Table>
		)
	}

	useEffect(() => {
		if (!filtersStore.loaded)
			dispatch(getCohortsFilters(`/api/v1/frontend/reports/cohorts/filters`, { _user_token, application_id: userInfoStore.data.selected.application }));
	}, [])

	useEffect(() => {
		const local = localStorage.getItem("cohortsAppliedFilters");
		const applied = JSON.stringify((appliedFilters));

		if (!initialized || local !== applied || selectedApp !== userInfoStore.data.selected.application) {
			setInitialized(true);
			setSelectedApp(userInfoStore.data.selected.application);
			if (Object.keys(appliedFilters).length > 0 && appliedFilters[`day[]`][0] && appliedFilters[`day[]`][1]) {
				updateReportsList(appliedFilters);
				localStorage.setItem("cohortsAppliedFilters", applied);
			}
		}
	}, [userInfoStore.data.selected.application, appliedFilters])

	return (
		<Container fluid className="px-0">

			{filtersStore.loaded &&
				<Row className="d-flex justify-content-between align-items-center pt-2 m-0">
					<Col xs={12} lg="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
						<CustomDatepicker
							dateFilterKey="day[]"
							appliedDate={appliedFilters[`day[]`]}
							changeDate={handleFilterDate}
							dateKey="reports"
							endCorrection={1}
							showToday
						/>
					</Col>

					<Col xs={12} lg="auto" className="px-0">
						<Row className="d-flex align-items-center m-0">

							<Col xs={6} lg="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
								<Filters
									availableFilters={filtersStore.data}
									appliedFilters={appliedFilters}
									handleAppliedFilters={handleAppliedFilters}
									applyFilters={() => updateReportsList(appliedFilters)}
								/>
							</Col>

							<Col xs={6} lg="auto" className="pt-3 pt-lg-0 px-0 px-lg-2">
								<a
									href={`${window.location.origin}/api/v1/frontend/reports/cohorts/reports?${filtersToURLparams({ _user_token, application_id: userInfoStore.data.selected.application, ...appliedFilters })}`}
									className="btn btn-secondary rounded-5"
									download="export_reports.csv"
								>
									{props.config.wording.text_1}
								</a>
							</Col>

							<Col xs={6} lg="auto" className="text-end pt-3 pt-lg-0 px-0 ps-lg-2 pe-lg-0">
								<CustomDropdown buttonText="Pick columns">
									<div className="white-space-nowrap py-2 px-3">
										{reportsStore.loaded && Object.keys(reportsStore.data.columns).map(col => col !== "day" &&
											<div className="py-1" key={col}>
												<Form.Label className="d-flex" htmlFor={col}>
													<Form.Check
														id={col}
														defaultChecked
														onChange={(e) => handleColumns(col, e.target.checked)}
													/>
													<span className="ps-2">
														{reportsStore.data.columns[col].name}
													</span>
												</Form.Label>
											</div>
										)}
									</div>
								</CustomDropdown>
							</Col>

						</Row>
					</Col>
				</Row>
			}

			{filtersStore.loaded &&
				<Row className="pt-2 m-0">
					<Col className="px-0">
						<AppliedFilters
							appliedFilters={appliedFilters}
							exceptFilters={["day[]", "page", "test"]}
							availableFilters={filtersStore.data}
							handleRemoveFilter={handleRemoveFilter}
						/>
					</Col>
				</Row>
			}

			{!reportsStore.loaded ? <Loader /> :
				<Container fluid className="px-0">
					<Row className="pt-4 m-0">
						<Col className="px-0">
							{generateTable()}
						</Col>
					</Row>

				</Container>
			}

		</Container >
	);
}