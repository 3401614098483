export const CUSTOMERS_DATA = "CUSTOMERS_DATA";
export const CUSTOMERS_LOADED = "CUSTOMERS_LOADED";
export const CUSTOMERS_ERROR = "CUSTOMERS_ERROR";

export const CUSTOMERS_FILTERS_DATA = "CUSTOMERS_FILTERS_DATA";
export const CUSTOMERS_FILTERS_LOADED = "CUSTOMERS_FILTERS_LOADED";
export const CUSTOMERS_FILTERS_ERROR = "CUSTOMERS_FILTERS_ERROR";

export const CUSTOMERS_STATUSES_DATA = "CUSTOMERS_STATUSES_DATA";
export const CUSTOMERS_STATUSES_LOADED = "CUSTOMERS_STATUSES_LOADED";
export const CUSTOMERS_STATUSES_ERROR = "CUSTOMERS_STATUSES_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export function customersReducer(state = defaultState, action) {
	switch (action.type) {
		case CUSTOMERS_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case CUSTOMERS_LOADED:
			return { ...state, loaded: action.payload };
		case CUSTOMERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function customersFiltersReducer(state = defaultState, action) {
	switch (action.type) {
		case CUSTOMERS_FILTERS_DATA:
			return { ...state, data: action.payload };
		case CUSTOMERS_FILTERS_LOADED:
			return { ...state, loaded: action.payload };
		case CUSTOMERS_FILTERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}

export function customersStatusesReducer(state = defaultState, action) {
	switch (action.type) {
		case CUSTOMERS_STATUSES_DATA:
			return { ...state, data: action.payload };
		case CUSTOMERS_STATUSES_LOADED:
			return { ...state, loaded: action.payload };
		case CUSTOMERS_STATUSES_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}