import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import userInfoReducer from "../reducers/userInfoReducer";
import appSettingsReducer from "../reducers/appSettingsReducer";
import platformsReducer from "../reducers/platformsReducer";
import integrationStatusesReducer from "../reducers/integrationStatusesReducer";

import {
	usersReducer,
	rolesReducer
} from "../reducers/teamMembersReducer";

import {
	overviewReducer,
	overviewFiltersReducer
} from "../reducers/overviewReducer";

import {
	installsReducer,
	installsFiltersReducer
} from "../reducers/installsReducer";

import {
	customersReducer,
	customersFiltersReducer,
	customersStatusesReducer
} from "../reducers/customersReducer";

import {
	cohortsReducer,
	asaReducer,
	cohortsFiltersReducer,
	asaFiltersReducer
} from "../reducers/reportsReducer";

import {
	eventsReducer,
	eventsFiltersReducer,
	eventsStatusesReducer
} from "../reducers/eventsReducer";

const rootReducer = combineReducers({
	overview: overviewReducer,
	overviewFilters: overviewFiltersReducer,
	userInfo: userInfoReducer,
	appSettings: appSettingsReducer,
	platforms: platformsReducer,
	installs: installsReducer,
	installsFilters: installsFiltersReducer,
	customers: customersReducer,
	customersFilters: customersFiltersReducer,
	customersStatuses: customersStatusesReducer,
	integrationStatuses: integrationStatusesReducer,
	cohorts: cohortsReducer,
	asa: asaReducer,
	cohortsFilters: cohortsFiltersReducer,
	asaFilters: asaFiltersReducer,
	events: eventsReducer,
	eventsFilters: eventsFiltersReducer,
	eventsStatuses: eventsStatusesReducer,
	roles: rolesReducer,
	users: usersReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));